// AWS Functions
import AWS from 'aws-sdk';
import * as libCon from '../Constants';
import axios from 'axios';





export const uploadToBucket = async (folderName, serial, fileName, obj) => {

  AWS.config.update({
    accessKeyId: await libCon.CONFIG(libCon.AWS_KEY),
    secretAccessKey: await libCon.CONFIG(libCon.AWS_SECRET),
  });

  const s3 = new AWS.S3({
    params: { Bucket: await libCon.CONFIG(libCon.AWS_BUCKET) },
    region: await libCon.CONFIG(libCon.AWS_REGION),
  });

  const key = `${folderName}/${serial}/${fileName}`

  const params = {
    Key: key,
    Body: JSON.stringify(obj),
  }


  try {

    await s3.putObject(params)
      .on("httpUploadProgress", (evt) => { console.log(`Uploading ${parseInt((evt.loaded * 100) / evt.total)}%`) }).promise();

    return libCon.OK

  } catch (error) {

    console.log(error)

    if (error.message === 'Network request failed')
      return libCon.NETWORK_ERROR

    return libCon.ERROR

  }
}


export const uploadImageToBucket = async (folderName, fileName, file, onProgress = (loaded, total) => false) => {

  AWS.config.update({
    accessKeyId: await libCon.CONFIG(libCon.AWS_KEY),
    secretAccessKey: await libCon.CONFIG(libCon.AWS_SECRET),
  });

  const s3 = new AWS.S3({
    params: { Bucket: await libCon.CONFIG(libCon.AWS_BUCKET) },
    region: await libCon.CONFIG(libCon.AWS_REGION),
  });


  const key = `${folderName}/${fileName}`

  const params = {
    Key: key,
    Body: file,
    ContentType: file.type,
  }

  try {

    await s3.upload(params)
      .on("httpUploadProgress", (progress) => onProgress(progress.loaded, progress.total)).promise();

    return libCon.OK

  } catch (error) {

    console.log(error)

    if (error.message === 'Network request failed')
      return libCon.NETWORK_ERROR

    return libCon.ERROR

  }
}



export const getFromBucket = async (url) => {

  AWS.config.update({
    accessKeyId: await libCon.CONFIG(libCon.AWS_KEY),
    secretAccessKey: await libCon.CONFIG(libCon.AWS_SECRET),
  });

  try {

    const config = {
      headers: {
        "Cache-Control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        'Access-Control-Allow-Methods': 'GET',
      }
    }

    const response = await axios.get(url, config);
    const data = response.data;

    return [libCon.OK, data]

  } catch (error) {
    console.log(error)

    if (error.message === 'Network request failed')
      return [libCon.NETWORK_ERROR, null]

    return [libCon.ERROR, null]
  }
};