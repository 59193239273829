// Airtable functions
import Airtable from "airtable";
import { format, formatISO, subMonths } from "date-fns";
import * as libCon from '../Constants';
import { nowInIndia } from "./dateFunctions";
import { exportToExcel } from "./ExcelFunctions";
import { arrayToDict, filterObjectByKeys, isNullOrUndefined, isNullOrUndefinedOrEmpty } from "./generalFunctions";






// General Methods
export const getDataFromTable = async (tableId) => {


    try {

        // Airtable
        const apiKey = await libCon.CONFIG(libCon.AT_API_KEY)
        const base = await libCon.CONFIG(libCon.AT_APP_ID)
        const at_base = new Airtable({ apiKey: apiKey }).base(base);

        let records = await at_base(tableId).select().all();
        records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })

        records = arrayToDict(records, libCon.ID)

        return [libCon.OK, records]
    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }

}


// Specific Methods
export const getAllParticipants = async () => {

    try {

        const [status, records] = await getDataFromTable(await libCon.CONFIG(libCon.AT_TABLE_PARTICIPANTS))
        return [status, records]
    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}

export const getAllSensors = async () => {

    try {

        const [status, records] = await getDataFromTable(await libCon.CONFIG(libCon.AT_TABLE_SENSORS))
        return [status, records]
    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}

export const getAllHouses = async () => {

    try {

        const [status, records] = await getDataFromTable(await libCon.CONFIG(libCon.AT_TABLE_HOUSES))
        return [status, records]
    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}


export const getAllEmails = async () => {

    try {

        const [status, records] = await getDataFromTable(await libCon.CONFIG(libCon.AT_TABLE_EMAILS))
        return [status, records]
    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}




export const getAllSensorHousePlacement = async () => {

    try {

        const [status, records] = await getDataFromTable(await libCon.CONFIG(libCon.AT_TABLE_SENSOR_HOUSE_PLACEMENT))
        return [status, records]
    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}

export const getAllParticipantPlacement = async () => {

    try {

        const [status, records] = await getDataFromTable(await libCon.CONFIG(libCon.AT_TABLE_PARTICIPANTS_PLACEMENT))
        return [status, records]
    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}

export const getAllPhones = async () => {

    try {

        const [status, records] = await getDataFromTable(await libCon.CONFIG(libCon.AT_TABLE_PHONES))
        return [status, records]
    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}


export const getAllPhonesPlacement = async () => {

    try {

        const [status, records] = await getDataFromTable(await libCon.CONFIG(libCon.AT_TABLE_PHONE_PLACEMENTS))
        return [status, records]
    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}

export const getAllWearables = async () => {

    try {

        const [status, records] = await getDataFromTable(await libCon.CONFIG(libCon.AT_TABLE_WEARABLES))
        return [status, records]
    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}

export const getAllWearablePlacements = async () => {

    try {

        const [status, records] = await getDataFromTable(await libCon.CONFIG(libCon.AT_TABLE_WEARABLE_PLACEMENTS))
        return [status, records]
    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}

export const getAllPendingIssues = async () => {

    try {

        // Airtable
        const apiKey = await libCon.CONFIG(libCon.AT_API_KEY)
        const base = await libCon.CONFIG(libCon.AT_APP_ID)
        const at_base = new Airtable({ apiKey: apiKey }).base(base);

        let records = await at_base(await libCon.CONFIG(libCon.AT_TABLE_ISSUES)).select({
            filterByFormula: `${libCon.ATF_STATUS} = '${libCon.ATF_PENDING}'`
        }).all();

        records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })
        records = arrayToDict(records, libCon.ID)

        return [libCon.OK, records]
    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}

export const getAllReceivedSensorFiles = async (monthsAgo = 2) => {


    try {

        // Airtable
        const apiKey = await libCon.CONFIG(libCon.AT_API_KEY)
        const base = await libCon.CONFIG(libCon.AT_APP_ID)
        const at_base = new Airtable({ apiKey: apiKey }).base(base);

        const startDate = subMonths(new Date(), monthsAgo);

        let records = await at_base(await libCon.CONFIG(libCon.AT_TABLE_RECEIVED_SENSOR_FILES)).select({
            filterByFormula: `IS_AFTER({${libCon.ATF_DATE_UPLOADED}}, '${formatISO(startDate)}')`
        }).all();

        records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })

        records = arrayToDict(records, libCon.ID)

        return [libCon.OK, records]
    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }

}




export const getPhoneBySerial = async (serial) => {

    try {

        // Airtable
        const apiKey = await libCon.CONFIG(libCon.AT_API_KEY)
        const base = await libCon.CONFIG(libCon.AT_APP_ID)
        const at_base = new Airtable({ apiKey: apiKey }).base(base);


        let records = await at_base(await libCon.CONFIG(libCon.AT_TABLE_PHONES)).select({
            filterByFormula: `${libCon.ATF_SERIAL} = '${serial}'`
        }).all();
        records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })

        records = arrayToDict(records, libCon.ID)

        return [libCon.OK, records]

    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}


export const getReceivedSensorFilesBySerial = async (serial) => {

    try {

        // Airtable
        const apiKey = await libCon.CONFIG(libCon.AT_API_KEY)
        const base = await libCon.CONFIG(libCon.AT_APP_ID)
        const at_base = new Airtable({ apiKey: apiKey }).base(base);


        let records = await at_base(await libCon.CONFIG(libCon.AT_TABLE_RECEIVED_SENSOR_FILES)).select({
            filterByFormula: `${libCon.ATF_SERIAL} = '${serial}'`
        }).all();
        records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })

        records = arrayToDict(records, libCon.ID)

        return [libCon.OK, records]

    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}


export const getSensorBySerial = async (serial) => {

    try {

        // Airtable
        const apiKey = await libCon.CONFIG(libCon.AT_API_KEY)
        const base = await libCon.CONFIG(libCon.AT_APP_ID)
        const at_base = new Airtable({ apiKey: apiKey }).base(base);


        let records = await at_base(await libCon.CONFIG(libCon.AT_TABLE_SENSORS)).select({
            filterByFormula: `${libCon.ATF_SERIAL} = '${serial}'`
        }).all();
        records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })

        records = arrayToDict(records, libCon.ID)

        return [libCon.OK, records]

    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}

export const getRecordByInternalId = async (table, internalId) => {

    if (isNullOrUndefined(internalId))
        return ([libCon.NOT_FOUND_ERROR, null])

    try {

        // Airtable
        const apiKey = await libCon.CONFIG(libCon.AT_API_KEY)
        const base = await libCon.CONFIG(libCon.AT_APP_ID)
        const at_base = new Airtable({ apiKey: apiKey }).base(base);

        let r = await at_base(table).find(internalId)

        let records = {
            [internalId]: {
                ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id
            }
        }

        return ([libCon.OK, records])

    } catch (error) {

        if (error.statusCode === 404)
            return ([libCon.NOT_FOUND_ERROR, null])

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }

}

export const getEmail = async (internalId) => {
    return await getRecordByInternalId(await libCon.CONFIG(libCon.AT_TABLE_EMAILS), internalId)
}
export const getWearablePlacement = async (internalId) => {
    return await getRecordByInternalId(await libCon.CONFIG(libCon.AT_TABLE_WEARABLE_PLACEMENTS), internalId)
}

export const getParticipant = async (internalId) => {
    return await getRecordByInternalId(await libCon.CONFIG(libCon.AT_TABLE_PARTICIPANTS), internalId)

}

export const getHouse = async (internalId) => {
    return await getRecordByInternalId(await libCon.CONFIG(libCon.AT_TABLE_HOUSES), internalId)

}

export const getPhonePlacementByPhoneSerial = async (phoneSerial) => {

    try {

        // Airtable
        const apiKey = await libCon.CONFIG(libCon.AT_API_KEY)
        const base = await libCon.CONFIG(libCon.AT_APP_ID)
        const at_base = new Airtable({ apiKey: apiKey }).base(base);


        let formula = `AND({${libCon.ATF_PHONE_SERIAL}} = '${phoneSerial}')`
        let records = await at_base(await libCon.CONFIG(libCon.AT_TABLE_PHONE_PLACEMENTS)).select({
            filterByFormula: formula
        }).all();
        records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })

        records = arrayToDict(records, libCon.ID)
        return [libCon.OK, records]

    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}


export const updateAccountLastSynched = async (email, lastSynched, lastHeartRateTimestamp) => {



    try {

        // Airtable
        const apiKey = await libCon.CONFIG(libCon.AT_API_KEY)
        const base = await libCon.CONFIG(libCon.AT_APP_ID)
        const at_base = new Airtable({ apiKey: apiKey }).base(base);

        let records = await at_base(await libCon.CONFIG(libCon.AT_TABLE_EMAILS)).select({
            filterByFormula: `{${libCon.ATF_EMAIL}} = '${email}'`
        }).all();


        records = await Promise.all(records.map(async r => {

            let fields = { [libCon.ATF_LAST_UPLOADED]: lastSynched }
            if (!isNullOrUndefined(lastHeartRateTimestamp))
                fields[libCon.ATF_LATEST_HEART_RATE_UPLOAD] = lastHeartRateTimestamp

            return await at_base(await libCon.CONFIG(libCon.AT_TABLE_EMAILS)).update(r.id, { ...fields })
        }
        ))



        records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })
        records = arrayToDict(records, libCon.ID)

        return ([libCon.OK, records])

    } catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }


}


export const exportParticipantsTable = async () => {


    try {

        // Airtable
        const apiKey = await libCon.CONFIG(libCon.AT_API_KEY)
        const base = await libCon.CONFIG(libCon.AT_APP_ID)
        const at_base = new Airtable({ apiKey: apiKey }).base(base);


        const cols = [libCon.ATF_PUBLIC_ID, libCon.ATF_ACTIVE, libCon.ATF_TRADE, libCon.ATF_SEWA_ID, libCon.ATF_TYPE, libCon.ATF_NAME, libCon.ATF_LAST_NAME, libCon.ATF_PHONE_NUMBER, libCon.ATF_JOINED_DATE, libCon.ATF_ADDRESS, libCon.ATF_NOTES]

        let records = await at_base(await libCon.CONFIG(libCon.AT_TABLE_PARTICIPANTS)).select({ view: libCon.AT_VIEW_PARTICIPANTS_PUBLIC }).all();
        records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })


        // filters
        records = records.map(r => filterObjectByKeys(r, cols))

        // Adjusts Address
        records = records.map(rec => { return ({ ...rec, [libCon.ATF_ADDRESS]: isNullOrUndefinedOrEmpty(rec[libCon.ATF_ADDRESS]) ? "" : rec[libCon.ATF_ADDRESS][0] }) })


        // Exports to Excel
        const now = new Date()
        exportToExcel(records, `Participants SEWA ${format(now, "yyyy-MM-d")}.xlsx`, cols)

        return (libCon.OK)

    } catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return (libCon.NETWORK_ERROR)

        return (libCon.AIRTABLE_ERROR)

    }


}


export const logBatteryChange = async (serial, date, time) => {

    try {

        // Airtable
        const apiKey = await libCon.CONFIG(libCon.AT_API_KEY)
        const base = await libCon.CONFIG(libCon.AT_APP_ID)
        const at_base = new Airtable({ apiKey: apiKey }).base(base);


        let records = await at_base(await libCon.CONFIG(libCon.AT_TABLE_SENSORS)).select({
            filterByFormula: `{${libCon.ATF_SERIAL}} = '${serial}'`
        }).all();


        // Update
        let dateString = `${date} ${time}`

        records = await Promise.all(records.map(async r => await at_base(await libCon.CONFIG(libCon.AT_TABLE_SENSORS)).update(r.id, { [libCon.ATF_LATEST_BATTERY_CHANGE]: dateString })))


        records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })
        records = arrayToDict(records, libCon.ID)


        return ([libCon.OK, records])

    } catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }



}


export const markIssueWithStatus = async (issue, noteText, status, tool = libCon.DASHBOARD) => {

    try {

        // Airtable
        const apiKey = await libCon.CONFIG(libCon.AT_API_KEY)
        const base = await libCon.CONFIG(libCon.AT_APP_ID)
        const at_base = new Airtable({ apiKey: apiKey }).base(base);


        let newText = isNullOrUndefinedOrEmpty(issue[libCon.ATF_NOTES]) ? "" : `${issue[libCon.ATF_NOTES]}`

        if (!isNullOrUndefinedOrEmpty(noteText))
            newText = `${newText}\n${nowInIndia()} (${tool}): ${noteText}`

        let vals = {
            [libCon.ATF_STATUS]: status,
            [libCon.ATF_NOTES]: newText
        }

        if (status === libCon.ATF_DONE)
            vals[libCon.ATF_DATE_SOLVED] = nowInIndia()

        let newIssue = await at_base(await libCon.CONFIG(libCon.AT_TABLE_ISSUES)).update(issue[libCon.ID], vals)

        let records = [newIssue]

        records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })
        records = arrayToDict(records, libCon.ID)


        return ([libCon.OK, records])

    } catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }

}


export const markIssueAsDone = async (issue, noteText, tool = libCon.DASHBOARD) => {
    return await markIssueWithStatus(issue, noteText, libCon.ATF_DONE, tool)
}


export const markIssueAsReadyForRevision = async (issue, noteText, tool = libCon.DASHBOARD) => {
    return await markIssueWithStatus(issue, noteText, libCon.ATF_READY_FOR_REVISION, tool)
}


export const getIssuesByIds = async (ids) => {

    try {

        // Airtable
        const apiKey = await libCon.CONFIG(libCon.AT_API_KEY)
        const base = await libCon.CONFIG(libCon.AT_APP_ID)
        const at_base = new Airtable({ apiKey: apiKey }).base(base);


        const filterFormula = `OR(${ids.map((id) => `RECORD_ID() = '${id}'`).join(",")})`;

        // Fetch records
        let records = await at_base(await libCon.CONFIG(libCon.AT_TABLE_ISSUES))
            .select({ filterByFormula: filterFormula })
            .all();

        records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })
        records = arrayToDict(records, libCon.ID)

        return ([libCon.OK, records])

    } catch (error) {
        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])
    }

}

export const getLatestGoveeActivationCodes = async (maxRecords = 4) => {


    try {

        // Airtable
        const apiKey = await libCon.CONFIG(libCon.AT_API_KEY)
        const base = await libCon.CONFIG(libCon.AT_APP_ID)
        const at_base = new Airtable({ apiKey: apiKey }).base(base);

        let records = await at_base(await libCon.CONFIG(libCon.AT_TABLE_GOVEE_ACTIVATION_CODES_ID)).select({
            maxRecords: maxRecords,
            sort: [{ field: libCon.ATF_DATE_CREATED, direction: 'desc' }]
        }).all();

        records = records.map((r) => r.fields)

        return ([libCon.OK, records])

    } catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }
}


export const buildAirtableFormLink = (baseLink, parameters) => {
    if (Object.keys(parameters).length === 0)
        return baseLink

    baseLink += "?"

    Object.keys(parameters).forEach(k => {
        baseLink += `prefill_${k.replace(/ /g, "+")}=${parameters[k].toString().replace(/ /g, "+")}&`
    })

    // Removes trailing &
    baseLink = baseLink.slice(0, -1);

    return baseLink

}



export const getPlacements = async (tableID, internalIds, onlyActive = true) => {

    if (isNullOrUndefinedOrEmpty(internalIds))
        return ([libCon.NOT_FOUND_ERROR, null])

    // Airtable
    const apiKey = await libCon.CONFIG(libCon.AT_API_KEY)
    const base = await libCon.CONFIG(libCon.AT_APP_ID)
    const at_base = new Airtable({ apiKey: apiKey }).base(base);

    let records = {}
    for (let i = 0; i < internalIds.length; i++) {
        let internalId = internalIds[i]

        try {



            let r = await at_base(tableID).find(internalId)


            if (!onlyActive || r.fields[libCon.ATF_ACTIVE] === libCon.YES)
                records[internalId] = { ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }


        } catch (error) {

            if (error.statusCode === 404)
                continue

            console.log(error)

            if (error.message === 'Network request failed')
                return ([libCon.NETWORK_ERROR, null])

            return ([libCon.AIRTABLE_ERROR, null])

        }
    }


    if (Object.keys(records).length > 0)
        return ([libCon.OK, records])
    else
        return ([libCon.NOT_FOUND_ERROR, null])
}



export const endPlacement = async (tableID, internalId) => {

    try {

        // Airtable
        const apiKey = await libCon.CONFIG(libCon.AT_API_KEY)
        const base = await libCon.CONFIG(libCon.AT_APP_ID)
        const at_base = new Airtable({ apiKey: apiKey }).base(base);


        let newPlacement = await at_base(tableID).update(internalId, {
            [libCon.ATF_END_DATE]: nowInIndia(true),
        })

        let records = [newPlacement]

        records = records.map((r) => { return ({ ...r.fields, [libCon.ID]: r.id, [libCon.KEY]: r.id }) })
        records = arrayToDict(records, libCon.ID)

        return ([libCon.OK, records])
    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }



}


export const uploadCollectionRecord = async (participantId, durationMinutes, status, notes = null, phoneSerial = null) => {

    try {

        // Airtable
        const apiKey = await libCon.CONFIG(libCon.AT_API_KEY)
        const base = await libCon.CONFIG(libCon.AT_APP_ID)
        const at_base = new Airtable({ apiKey: apiKey }).base(base);


        let fields = {
            [libCon.ATF_DATE_OF_COLLECTION]: nowInIndia(true),
        }

        if (!isNullOrUndefinedOrEmpty(participantId))
            fields[libCon.ATF_PARTICIPANT] = [participantId]

        if (!isNullOrUndefinedOrEmpty(durationMinutes))
            fields[libCon.ATF_DURATION] = 60 * parseInt(durationMinutes)

        if (!isNullOrUndefinedOrEmpty(status))
            fields[libCon.ATF_STATUS] = status

        if (!isNullOrUndefinedOrEmpty(notes))
            fields[libCon.ATF_NOTES] = notes

        if (!isNullOrUndefined(phoneSerial))
            fields[libCon.ATF_PHONE_SERIAL] = phoneSerial


        let record = await at_base(await libCon.CONFIG(libCon.AT_TABLE_COLLECTION_RECORDS)).create(fields)

        let records = {
            [record[libCon.ID]]: {
                ...record[libCon.FIELDS]
            }
        }

        return ([libCon.OK, records])
    }
    catch (error) {

        console.log(error)

        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.AIRTABLE_ERROR, null])

    }



}


export const getIssueColumnsByLocale = (locale) => {
    switch (locale) {
        case libCon.EN:
            return [libCon.ATF_DESCRIPTION, libCon.ATF_ACTION]
        case libCon.GU:
            return [libCon.ATF_DESCRIPTION_GU, libCon.ATF_ACTION_GU]
        case libCon.HI:
            return [libCon.ATF_DESCRIPTION_HI, libCon.ATF_ACTION_HI]
        case libCon.ES:
            return [libCon.ATF_DESCRIPTION_ES, libCon.ATF_ACTION_ES]
        default:
            return [libCon.ATF_DESCRIPTION, libCon.ATF_ACTION]
    }
}