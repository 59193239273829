import React, { useEffect, useState } from 'react'
import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import { RefATObjectInsideSetStatus, RefATOField, RefATOInsideSetField, RefKeysOfATOSet, setStoredValue, StateStoredBooleanValue } from '../hooks/StoreHooks';
import { RefLanguage } from '../hooks/LanguageHooks';
import CustomText from '../elements/CustomText';
import { SimpleCopyFieldFromATOInsideSet } from '../elements/SimpleStoreCopyField';
import { isNullOrUndefinedOrEmpty } from '../community-hats-js-library/utils/generalFunctions';
import { LocationExtractorFroATOInsideSetFields } from './LocationExtractor';
import { buildAirtableFormLink } from '../community-hats-js-library/utils/airtableFunctions';
import SimpleLink from '../elements/SimpleLink';
import { SimpleStoreCopySelectionFieldFromATOInsideSet } from '../elements/SimpleStoreCopySelectionField';
import { SyncATOInsideSetButton } from '../elements/SyncATOButton';
import CheckboxGroup from '../elements/CheckboxGroup';



export function SensorPlacementViewer({ positionId, positionNumber }) {

    const i18n = RefLanguage()

    const sensorId = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_SENSOR)


    // Used Fields
    const sensorPublicId = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSOR, libCon.ATF_PUBLIC_ID)
    const housePublicId = RefATOField(locCon.AT_OBJECT_HOUSE, libCon.ATF_PUBLIC_ID)

    const lat = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_LATITUDE)
    const lon = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_LONGITUDE)
    const acc = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_ACCURACY)
    const tagColor = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_TAG_COLOR)
    const tagCode = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_TAG_CODE)
    const position = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_POSITION)

    // Placement Status
    const status = RefATObjectInsideSetStatus(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT)

    // Add Sensor Placement Link
    const [placementLink, setPlacementLink] = useState(null)
    const [placementLinkMessage, setPlacementLinkMessage] = useState(null)

    // Support Variables
    const [linkClicked, setlinkClicked] = StateStoredBooleanValue(positionId + locCon.LINK_CLICKED)



    useEffect(() => {

        let checkFunction = async () => {

            let vals = {
                [libCon.ATF_SENSOR]: sensorPublicId,
                [libCon.ATF_LOCATION]: housePublicId,
                [libCon.ATF_LATITUDE]: lat,
                [libCon.ATF_LONGITUDE]: lon,
                [libCon.ATF_ACCURACY]: acc,
                [libCon.ATF_TAG_COLOR]: tagColor,
                [libCon.ATF_TAG_CODE]: tagCode,
                [libCon.ATF_POSITION]: position,
            }

            if (Object.values(vals).some(isNullOrUndefinedOrEmpty)) {
                setPlacementLink(null)
                let message = `${i18n.t("missing")}: ` + Object.keys(vals).filter(k => isNullOrUndefinedOrEmpty(vals[k])).join(", ")
                setPlacementLinkMessage(message)

            }
            else {
                const link = buildAirtableFormLink(await libCon.CONFIG(libCon.AT_TABLE_SENSOR_PLACEMENT_FORM), vals)

                setPlacementLink(link)
                setPlacementLinkMessage(null)

            }

        }

        checkFunction()



    }, [sensorPublicId, housePublicId, lat, lon, acc, tagCode, tagColor, position, i18n])


    return (

        <div className='verticalSection' style={{ backgroundColor: "var(--background-color-2)", marginTop: "1vh", marginBottom: "1vh", width: "95%" }}>

            <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("sensorPlacement") + " " + positionNumber}</CustomText>

            <CheckboxGroup disableClick={true} mainCheckbox={{
                [libCon.ID]: locCon.getASHPCheckBoxId(positionId),
                [libCon.TEXT]: i18n.t("sensorPlacementToDatabase"),
                [libCon.CONTAINER]: <div>
                    <div style={{ marginTop: 15 }}>
                        {isNullOrUndefinedOrEmpty(sensorId) ? <CustomText type={locCon.DANGER_TEXT}>{i18n.t("pleaseSyncSensor")}</CustomText> : <div></div>}

                        <SimpleCopyFieldFromATOInsideSet title={i18n.t("serial")} atoId={locCon.AT_OBJECT_SENSOR} positionId={positionId} fieldId={libCon.ATF_SERIAL} enableCopy={false} />
                    </div>
                    <div className='horizontalSection'>
                        <SimpleCopyFieldFromATOInsideSet title={i18n.t("brand")} atoId={locCon.AT_OBJECT_SENSOR} positionId={positionId} fieldId={libCon.ATF_BRAND} enableCopy={false} />
                        <SimpleCopyFieldFromATOInsideSet title={i18n.t("model")} atoId={locCon.AT_OBJECT_SENSOR} positionId={positionId} fieldId={libCon.ATF_MODEL} enableCopy={false} />
                    </div>

                    <LocationExtractorFroATOInsideSetFields positionId={positionId} atoId={locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT} latId={libCon.ATF_LATITUDE} lonId={libCon.ATF_LONGITUDE} accId={libCon.ATF_ACCURACY} enableCopy={false} />

                    <div style={{ marginLeft: "5%", marginRight: "5%", marginTop: "2.5vh" }}>
                        <SimpleStoreCopySelectionFieldFromATOInsideSet title={i18n.t("senorPosition")} positionId={positionId} atoId={locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT} fieldId={libCon.ATF_POSITION} options={libCon.SENSOR_POSITIONS} optionsTextDict={libCon.SENSOR_POSITIONS.reduce((dict, item) => { dict[item] = i18n.t(item); return dict; }, {})} />
                    </div>

                    <div className='verticalSection' style={{ marginTop: "2.5vh", marginBottom: "1.5vh" }}>
                        <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("sensorPlacementLink")}</CustomText>
                        {
                            placementLink !== null
                                ? <SimpleLink text={i18n.t("addSensorPlacement")} link={placementLink} onClickCleanup={() => setlinkClicked(true)} />
                                : <CustomText type={locCon.DANGER_TEXT}>{placementLinkMessage}</CustomText>
                        }
                    </div>

                    <div className='verticalSection'>
                        {
                            linkClicked || status === locCon.ATO_UP_TO_DATE
                                ? <SyncATOInsideSetButton positionId={positionId} atoId={locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT} atoBehavior={libCon.ATO_BEHAVIOR_PULL} customMessages={{ [libCon.NOT_FOUND_ERROR]: i18n.t("sensorPlacementNotFound") }} onClickCleanup={(val) => setStoredValue(locCon.getASHPCheckBoxId(positionId), val)} isNew={true} />
                                : <CustomText type={locCon.INSTRUCTIONS_TEXT} style={{ marginBottom: "3vh" }}>{i18n.t("followSensorPlacementLink")}</CustomText>

                        }
                    </div>

                </div>
            }} />
        </div>

    )
}




function SensorPlacementSetViewer() {


    const keys = RefKeysOfATOSet()

    const i18n = RefLanguage()
    return (
        <div className='verticalSection' style={{ marginTop: 10 }}>
            {keys.length === 0 ? <CustomText type={locCon.DANGER_TEXT}>{i18n.t("pleaseAddSensors")}</CustomText> : <div></div>}
            {
                keys.map((k, i) => {
                    return (<SensorPlacementViewer key={k} positionId={k} positionNumber={i + 1} />
                    )
                })
            }

        </div>
    )
}

export default SensorPlacementSetViewer