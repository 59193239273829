
import { RefLanguage, RefLanguageAndLocale } from '../hooks/LanguageHooks';
import { RefStoredValue, RestartHealthForm, setStoredValue, StateStoredValue } from '../hooks/StoreHooks';
import { useEffect, useState } from 'react';
import * as libCon from "./../community-hats-js-library/Constants"
import * as locCon from "./../LocalConstants"

import CustomCheckBox from '../elements/CustomCheckBox';
import CustomText from '../elements/CustomText';
import { SimpleStoreCopySelectionFieldFromId } from '../elements/SimpleStoreCopySelectionField';
import { dictFromArrays, isNullOrUndefined, isNumeric } from '../community-hats-js-library/utils/generalFunctions';
import { buildImageUrl, showNotification } from '../utils/generalFunctions';
import CustomButton from '../elements/CustomButton';
import CustomSpin from '../elements/CustomSpin';
import { SimpleStoreCopyFieldFromId } from '../elements/SimpleStoreCopyField';
import { useNavigate } from 'react-router-dom';
import { SimpleLikertScaleFromId } from '../elements/SimpleLikertScale';
import { BloodPressureForm, OralTemperatureForm } from './../components/PerceptualSurveyComponents';
import { SurveyProgress } from '../hooks/SurveyHooks';
import { buildSurveyExport } from '../utils/surveyFunctions';
import { uploadToBucket } from '../community-hats-js-library/utils/AWSFunctions';
import { SimpleMultipleSelectFromId } from './SimpleMultipleSelect';
import { SimpleRadioButtonImagesFromId } from './SimpleRadioButton';
import { SimpleImageUploadFromId } from './SimpleImageUpload';
import { SimpleStoreCopyTextAreaFromId } from './SimpleStoreCopyTextArea';


const STATE_NOT_STARTED = "STATE_NOT_STARTED"
const STATE_LOADING_FORM = "STATE_LOADING_FORM"
const STATE_ANSWERING = "STATE_ANSWERING"
const STATE_SENDING_ANSWERS = "STATE_SENDING_ANSWERS"
const STATE_COMPLETED = "STATE_COMPLETED"
const STATE_FAILED_TO_UPLOAD = "STATE_FAILED_TO_UPLOAD"


export const QuestionAnsweredComponent = ({ questionId }) => {


    const value = RefStoredValue(questionId)

    const i18n = RefLanguage()

    return (!isNullOrUndefined(value)
        ? <div className='horizontalSectionCenter' style={{ marginBottom: "2vh" }}>
            <CustomCheckBox type={locCon.MAIN_PROGRESS_CHECKBOX} checked={true} setValue={(val) => true} />
            <div>
                <CustomText type={locCon.SUCCESS_TEXT}>{i18n.t("questionAnsweredSuccessfully")}</CustomText>
            </div>
        </div>
        : <div className='horizontalSectionCenter'>
            <CustomText type={locCon.DANGER_TEXT}>{i18n.t("questionNotAnswered")}</CustomText>
        </div>)


}



export const GenericSurveyFormStructureButton = ({ surveyConfiguration, configurationStatusId, sendingStatusId }) => {

    const i18n = RefLanguage()

    const surveyStatus = RefStoredValue(configurationStatusId)

    const loadSurvey = async () => {
        setStoredValue(sendingStatusId, libCon.STATUS_NOT_STARTED)
        setStoredValue(configurationStatusId, libCon.STATUS_OK)

    }

    return (
        <div className='verticalSection'>
            <CustomText type={locCon.SUCCESS_TEXT}>{`${i18n.t("surveyVersion")}: ${surveyConfiguration[libCon.SURVEY_METADATA][libCon.SURVEY_VERSION]}`}</CustomText>

            <div className='verticalSection' style={{ marginTop: "1vh" }} >
                {
                    surveyStatus === libCon.STATUS_NOT_STARTED
                        ? <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => loadSurvey()}>{i18n.t("loadFormSurvey")}</CustomButton>
                        : <div></div>
                }
            </div>
        </div>
    )
}


export const GenericSurveyProgressComponentAndExport = ({ surveyConfiguration,
    sendingStatusId,
    configurationStatusId,
    buildFilename,
    fodlerName,
    encoder,
    metaBuilder,
    getSurveySerial }) => {

    const [totalQuestions, answeredQuestions] = SurveyProgress(surveyConfiguration, encoder)

    const buildExport = () => buildSurveyExport(surveyConfiguration, encoder, metaBuilder)

    const uploadSurvey = (serial, fileName, toUpload) => uploadToBucket(fodlerName, serial, fileName, toUpload)


    const i18n = RefLanguage()

    const navigate = useNavigate();

    const [canExport, setCanExport] = useState(() => totalQuestions !== null && totalQuestions <= answeredQuestions)


    const [formPublishedStatus, setFormPublishedStatus] = StateStoredValue(sendingStatusId)
    const [formStatus, setFormStatus] = StateStoredValue(configurationStatusId)


    const [hasError, setHasError] = useState(false)

    useEffect(() => {
        setFormPublishedStatus(libCon.STATUS_NOT_STARTED)
    }, [setFormPublishedStatus])



    useEffect(() => {
        setCanExport(totalQuestions !== null && totalQuestions <= answeredQuestions)
    }, [totalQuestions, answeredQuestions])


    const localExport = async () => {


        setStoredValue(sendingStatusId, libCon.STATUS_LOADING)


        // Builds
        const toUpload = buildExport()
        const fileName = buildFilename()

        let serial = getSurveySerial()
        if (serial === null)
            serial = libCon.MISSING

        // Exports
        const reponse = await uploadSurvey(serial, fileName, toUpload)

        if (reponse === libCon.OK) {
            setStoredValue(sendingStatusId, libCon.STATUS_OK)

            if (surveyConfiguration !== null && surveyConfiguration !== undefined)
                RestartHealthForm(surveyConfiguration[libCon.SURVEY_STRUCTURE].map(q => encoder(q[libCon.SURVEY_QUESTION_ID])))

            setFormStatus(libCon.STATUS_NOT_STARTED)
            setHasError(false)

            showNotification(i18n.t("surveyUploadedCorrectly"))
        }
        else {
            setHasError(true)
            setStoredValue(sendingStatusId, libCon.STATUS_ERROR)

        }

    }


    const [currentState, setCurrentState] = useState(() => STATE_NOT_STARTED)


    useEffect(() => {

        if (formStatus === libCon.STATUS_NOT_STARTED) {
            if (formPublishedStatus === libCon.STATUS_OK)
                setCurrentState(STATE_COMPLETED)
            else
                setCurrentState(STATE_NOT_STARTED)

        }
        else {
            if (formPublishedStatus === libCon.STATUS_LOADING)
                setCurrentState(STATE_SENDING_ANSWERS)
            else {
                if (hasError)
                    setCurrentState(STATE_FAILED_TO_UPLOAD)
                else
                    setCurrentState(STATE_ANSWERING)
            }


        }

    }, [surveyConfiguration, formPublishedStatus, hasError, canExport, formStatus])




    // Component has four states

    switch (currentState) {
        case STATE_NOT_STARTED:
            return (<CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("pleaseLoadFormSurvey")}</CustomText>)
        case STATE_COMPLETED:
            return (
                <div className="verticalSection" style={{ marginTop: 25 }}>
                    <CustomText type={locCon.SUCCESS_TEXT}>{i18n.t("formSurveySubmitted")}</CustomText>
                    <CustomButton type={locCon.PRIMARY_BUTTON} style={{ marginTop: 10 }} onClick={() => navigate(-1)}>
                        {i18n.t("goBack")}
                    </CustomButton>
                </div>)
        case STATE_LOADING_FORM:
            return (<div></div>)
        case STATE_SENDING_ANSWERS:
            return (<div className="verticalSection" >
                <CustomText type={locCon.SUCCESS_TEXT}>{`Total answered questions: ${answeredQuestions} of ${totalQuestions}`}</CustomText>
                <CustomText type={locCon.DANGER_TEXT}></CustomText>
                <CustomSpin type={locCon.BUTTON_SPINNER} />
            </div>)
        case STATE_ANSWERING:
            return (<div className="verticalSection" style={{ marginBottom: 5 }}>
                <CustomText type={locCon.SUCCESS_TEXT}>{`Total answered questions: ${answeredQuestions} of ${totalQuestions}`}</CustomText>
                <CustomText type={locCon.DANGER_TEXT}></CustomText>
                <CustomButton type={canExport ? locCon.PRIMARY_BUTTON : locCon.PRIMARY_BUTTON_DISSABLED_BUTTON} onClick={() => canExport ? localExport() : showNotification(i18n.t("pleaseFillAllQuestions"))}>
                    {i18n.t("sendSurveyForm")}
                </CustomButton>
            </div>)
        case STATE_FAILED_TO_UPLOAD:
            return (<div className="verticalSection" style={{ marginBottom: 5 }}>
                <CustomText type={locCon.SUCCESS_TEXT}>{`Total answered questions: ${answeredQuestions} of ${totalQuestions}`}</CustomText>
                <CustomText type={locCon.DANGER_TEXT}>{i18n.t("errorSendingForm")}</CustomText>
                <CustomButton type={canExport ? locCon.PRIMARY_BUTTON : locCon.PRIMARY_BUTTON_DISSABLED_BUTTON} onClick={() => canExport ? localExport() : showNotification(i18n.t("pleaseFillAllQuestions"))}>
                    {i18n.t("sendSurveyForm")}
                </CustomButton>
            </div>)
        default:
            return (<div></div>);
    }



}



export const GenericSurveyQuestions = ({ encoder, surveyConfiguration, configurationStatusId, instructionsText }) => {

    const surveyStatus = RefStoredValue(configurationStatusId)

    const i18n = RefLanguage()

    const restartProcess = (showMessage = true) => {
        if (surveyConfiguration !== null && surveyConfiguration !== undefined)
            RestartHealthForm(surveyConfiguration[libCon.SURVEY_STRUCTURE].map(q => encoder(q[libCon.SURVEY_QUESTION_ID])))

        if (showMessage)
            showNotification(i18n.t("formRestarted"))

    }


    return (
        <div className='verticalSection'>
            {surveyStatus !== libCon.STATUS_NOT_STARTED
                ? <div className='verticalSection'>
                    <div className='horizontalLine' />
                    <CustomText type={locCon.INFO_TEXT} style={{ marginBottom: 6 }}>{instructionsText}</CustomText>
                    <div style={{ width: "100%", marginBottom: "1vh", marginTop: "1vh" }}>
                        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => restartProcess()}>{i18n.t("restartSurveyForm")}</CustomButton>
                    </div>
                    <div className='horizontalLine' />
                    {
                        surveyConfiguration[libCon.SURVEY_STRUCTURE].map((conf, i) =>
                            <div className='verticalSection' key={conf[libCon.SURVEY_QUESTION_ID]}>
                                <SurveyComponent configuration={conf} position={i} encoder={encoder} />
                                <div className='horizontalLine' />
                            </div>)
                    }
                </div>
                : <div></div>
            }
        </div>)

}




export const SurveyComponent = ({ configuration, position, encoder }) => {


    const [i18n, currentLocale] = RefLanguageAndLocale()


    const [currentTranslation, setCurrentTranslation] = useState(() => currentLocale in configuration[libCon.SURVEY_QUESTION_TRANSLATION] ? configuration[libCon.SURVEY_QUESTION_TRANSLATION][currentLocale] : configuration)

    useEffect(() => {

        let newTranslation = currentLocale in configuration[libCon.SURVEY_QUESTION_TRANSLATION] ? configuration[libCon.SURVEY_QUESTION_TRANSLATION][currentLocale] : configuration

        setCurrentTranslation({ ...newTranslation })


    }, [currentLocale, configuration])


    const MainComponent = () => {

        if (configuration[libCon.SURVEY_QUESTION_TYPE] === libCon.SURVEY_QUESTION_TYPE_CUSTOM) {
            switch (configuration[libCon.SURVEY_QUESTION_ID]) {
                case libCon.HF_CUSTOM_BLOOD_PRESSURE:
                    return (<BloodPressureForm title={`${position + 1}. ${currentTranslation[libCon.SURVEY_QUESTION_TITLE]}`} infoText={currentTranslation[libCon.SURVEY_QUESTION_INFO_TEXT]} />)
                case libCon.HF_CUSTOM_ORAL_TEMPERATURE:
                    return (<OralTemperatureForm title={`${position + 1}. ${currentTranslation[libCon.SURVEY_QUESTION_TITLE]}`} infoText={currentTranslation[libCon.SURVEY_QUESTION_INFO_TEXT]} />)
                default:
                    return (<div></div>)
            }
        }
        else {
            switch (configuration[libCon.SURVEY_QUESTION_TYPE]) {
                case libCon.SURVEY_QUESTION_TYPE_MULTIPLE_CHOICE:
                    return (<SimpleStoreCopySelectionFieldFromId
                        valueID={encoder(configuration[libCon.SURVEY_QUESTION_ID])}
                        title={`${position + 1}. ${currentTranslation[libCon.SURVEY_QUESTION_TITLE]}`}
                        infoText={currentTranslation[libCon.SURVEY_QUESTION_INFO_TEXT]}
                        options={configuration[libCon.SURVEY_MULTIPLE_CHOICE_OPTIONS]}
                        defaultValue={libCon.NA}
                        checkboxText={i18n.t("informationNotAvailable")}
                        includeCheckbox={libCon.SURVEY_QUESTION_INCLUDE_NA in configuration ? configuration[libCon.SURVEY_QUESTION_INCLUDE_NA] : false}
                        enableCopy={false}
                        includeOther={libCon.SURVEY_MULTIPLE_CHOICE_INCLUDE_OTHER in configuration && configuration[libCon.SURVEY_MULTIPLE_CHOICE_INCLUDE_OTHER] === true}
                        optionsTextDict={dictFromArrays(configuration[libCon.SURVEY_MULTIPLE_CHOICE_OPTIONS], currentTranslation[libCon.SURVEY_MULTIPLE_CHOICE_OPTIONS])}
                    />
                    )
                case libCon.SURVEY_QUESTION_TYPE_MULTIPLE_CHOICE_IMAGES:
                    return (<SimpleRadioButtonImagesFromId
                        valueID={encoder(configuration[libCon.SURVEY_QUESTION_ID])}
                        title={`${position + 1}. ${currentTranslation[libCon.SURVEY_QUESTION_TITLE]}`}
                        infoText={currentTranslation[libCon.SURVEY_QUESTION_INFO_TEXT]}
                        options={configuration[libCon.SURVEY_MULTIPLE_CHOICE_OPTIONS]}
                        optionsTextDict={dictFromArrays(configuration[libCon.SURVEY_MULTIPLE_CHOICE_OPTIONS], currentTranslation[libCon.SURVEY_MULTIPLE_CHOICE_OPTIONS])}
                        optionsImagesDict={dictFromArrays(configuration[libCon.SURVEY_MULTIPLE_CHOICE_OPTIONS], configuration[libCon.SURVEY_MULTIPLE_CHOICE_IMAGES].map(p => buildImageUrl(p)))}
                        defaultValue={libCon.NA}
                        checkboxText={i18n.t("informationNotAvailable")}
                        includeCheckbox={libCon.SURVEY_QUESTION_INCLUDE_NA in configuration ? configuration[libCon.SURVEY_QUESTION_INCLUDE_NA] : false}
                        includeOther={libCon.SURVEY_MULTIPLE_CHOICE_INCLUDE_OTHER in configuration && configuration[libCon.SURVEY_MULTIPLE_CHOICE_INCLUDE_OTHER] === true}
                    />
                    )
                case libCon.SURVEY_QUESTION_TYPE_BINARY:

                    return (
                        <SimpleLikertScaleFromId valueID={encoder(configuration[libCon.SURVEY_QUESTION_ID])}
                            title={`${position + 1}. ${currentTranslation[libCon.SURVEY_QUESTION_TITLE]}`}
                            infoText={currentTranslation[libCon.SURVEY_QUESTION_INFO_TEXT]}
                            options={[configuration[libCon.SURVEY_BINARY_OPTION_NO], configuration[libCon.SURVEY_BINARY_OPTION_YES]]}
                            optionText={[currentTranslation[libCon.SURVEY_BINARY_OPTION_NO], currentTranslation[libCon.SURVEY_BINARY_OPTION_YES]]}
                            checkboxText={i18n.t("informationNotAvailable")}
                            includeCheckbox={libCon.SURVEY_QUESTION_INCLUDE_NA in configuration ? configuration[libCon.SURVEY_QUESTION_INCLUDE_NA] : false}
                            defaultValue={libCon.NA}
                        />
                    )
                case libCon.SURVEY_QUESTION_TYPE_NUMERIC:


                    const numericCheck = (val) => {
                        if (val === libCon.NA)
                            return true

                        if (!isNumeric(val))
                            return false

                        if (libCon.SURVEY_QUESTION_MIN_VALUE in configuration &&
                            val < configuration[libCon.SURVEY_QUESTION_MIN_VALUE])
                            return false

                        if (libCon.SURVEY_QUESTION_MAX_VALUE in configuration &&
                            val > configuration[libCon.SURVEY_QUESTION_MAX_VALUE])
                            return false

                        return true

                    }

                    return (
                        <SimpleStoreCopyFieldFromId valueID={encoder(configuration[libCon.SURVEY_QUESTION_ID])}
                            title={`${position + 1}. ${currentTranslation[libCon.SURVEY_QUESTION_TITLE]}`}
                            infoText={currentTranslation[libCon.SURVEY_QUESTION_INFO_TEXT]}
                            defaultValue={libCon.NA}
                            checkboxText={i18n.t("informationNotAvailable")}
                            precision={libCon.SURVEY_QUESTION_PRECISION in configuration ? configuration[libCon.SURVEY_QUESTION_PRECISION] : 0}
                            includeCheckbox={libCon.SURVEY_QUESTION_INCLUDE_NA in configuration ? configuration[libCon.SURVEY_QUESTION_INCLUDE_NA] : false}
                            marginBottom={5}
                            placeHolder={i18n.t("answer")}
                            isInputNumeric={true}
                            checkFunction={numericCheck} />
                    )

                case libCon.SURVEY_QUESTION_TYPE_MULTIPLE_CHOICE_AND_ANSWER:

                    let noneText = i18n.t("noneOfTheOptions")
                    if (libCon.SURVEY_MULTIPLE_CHOICE_NONE_TEXT in currentTranslation)
                        noneText = currentTranslation[libCon.SURVEY_MULTIPLE_CHOICE_NONE_TEXT]

                    return (
                        <SimpleMultipleSelectFromId
                            valueID={encoder(configuration[libCon.SURVEY_QUESTION_ID])}
                            title={`${position + 1}. ${currentTranslation[libCon.SURVEY_QUESTION_TITLE]}`}
                            infoText={currentTranslation[libCon.SURVEY_QUESTION_INFO_TEXT]}
                            options={configuration[libCon.SURVEY_MULTIPLE_CHOICE_OPTIONS]}
                            defaultValue={libCon.NA}
                            checkboxText={i18n.t("informationNotAvailable")}
                            includeCheckbox={libCon.SURVEY_QUESTION_INCLUDE_NA in configuration ? configuration[libCon.SURVEY_QUESTION_INCLUDE_NA] : false}
                            enableCopy={false}
                            noneOptionText={noneText}
                            optionsTextDict={dictFromArrays(configuration[libCon.SURVEY_MULTIPLE_CHOICE_OPTIONS], currentTranslation[libCon.SURVEY_MULTIPLE_CHOICE_OPTIONS])} />
                    )


                case libCon.SURVEY_QUESTION_TYPE_IMAGE_UPLOAD:

                    return (
                        <SimpleImageUploadFromId
                            valueID={encoder(configuration[libCon.SURVEY_QUESTION_ID])}
                            title={`${position + 1}. ${currentTranslation[libCon.SURVEY_QUESTION_TITLE]}`}
                            infoText={currentTranslation[libCon.SURVEY_QUESTION_INFO_TEXT]}
                            infoImage={libCon.SURVEY_QUESTION_IMAGE in configuration ? buildImageUrl(configuration[libCon.SURVEY_QUESTION_IMAGE]) : null}
                            defaultValue={libCon.NA}
                            checkboxText={i18n.t("informationNotAvailable")}
                            includeCheckbox={libCon.SURVEY_QUESTION_INCLUDE_NA in configuration ? configuration[libCon.SURVEY_QUESTION_INCLUDE_NA] : false}
                        />
                    )
                case libCon.SURVEY_QUESTION_TYPE_TEXT_AREA:

                    return (
                        <SimpleStoreCopyTextAreaFromId
                            valueID={encoder(configuration[libCon.SURVEY_QUESTION_ID])}
                            title={`${position + 1}. ${currentTranslation[libCon.SURVEY_QUESTION_TITLE]}`}
                            infoText={currentTranslation[libCon.SURVEY_QUESTION_INFO_TEXT]}
                            defaultValue={libCon.NA}
                            checkboxText={i18n.t("nothingToAdd")}
                            includeCheckbox={libCon.SURVEY_QUESTION_INCLUDE_NA in configuration ? configuration[libCon.SURVEY_QUESTION_INCLUDE_NA] : false}
                        />
                    )


                default:
                    return (<div></div>)
            }

        }
    }

    return (<div className='verticalSection'>
        <MainComponent />
        <QuestionAnsweredComponent questionId={encoder(configuration[libCon.SURVEY_QUESTION_ID])} />
    </div>)
}




// Specific Components

