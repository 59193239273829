import React, { useEffect, useState } from "react";
import * as locCon from "../LocalConstants"
import * as libCon from "../community-hats-js-library/Constants"
import { RefLanguage } from "../hooks/LanguageHooks";
import CustomText from "./CustomText";
import CustomCheckBox, { CheckboxDefaultValue } from "./CustomCheckBox";
import { setStoredValue, StateStoredBooleanValue, StateStoredValue } from "../hooks/StoreHooks";
import { isEmpty, isNullOrUndefined, isNullOrUndefinedOrEmptyOrMissing } from "../community-hats-js-library/utils/generalFunctions";
import { SimpleStoreCopyField } from "./SimpleStoreCopyField";


export function SimpleMultipleSelectFromId({ valueID, ...props }) {
    const [value, setValue] = StateStoredValue(valueID)


    return (<SimpleMultipleSelect allValues={value}
        setAllValues={setValue}
        otherValueId={valueID + locCon.OTHER_VALUE}
        {...props} />)
}



export function SimpleMultipleSelect({ allValues,
    setAllValues,
    options,
    optionsTextDict,
    title,
    infoText,
    includeOther = true,
    otherValueId,
    includeAnswer = true,
    includeCheckbox = false,
    defaultValue = libCon.NA,
    checkboxText,
    noneOptionText = "None of the previuos pptions" }) {

    const i18n = RefLanguage()

    const [isDisabled, setIsDisabled] = useState(() => allValues === libCon.NA)
    const [areValuesEmpty, setAreValuesEmpty] = useState(() => isEmpty(allValues))

    const toggleAreValuesEmpty = () => {
        if (areValuesEmpty) {
            setAreValuesEmpty(false)
            setOtherIncluded(false)
            setAllValues(null)

        }
        else {
            setAreValuesEmpty(true)
            setOtherIncluded(false)
            setAllValues([])

        }
    }

    useEffect(() => {
        setAreValuesEmpty(isEmpty(allValues))
    }, [allValues])



    // Checkbox
    const [otherIncluded, setOtherIncluded] = useState(false)

    // Other Value
    const [other, setOther] = StateStoredValue(otherValueId)
    const [editingOther, setEditingOther] = StateStoredBooleanValue(otherValueId + locCon.EDITING_VALUE)


    useEffect(() => {
        let newIsDisabled = allValues === libCon.NA
        setIsDisabled(newIsDisabled)

        if (newIsDisabled) {
            setStoredValue(otherValueId, null)
            setOtherIncluded(false)
        }
    }, [allValues, otherValueId])


    const toggleIncludeOtherAfterSave = (isEditing, value) => {
        if (!isEditing) {
            addOtherValue(value, allValues, setAllValues, options)
            setOtherIncluded(true)
        }
        else {
            removeOtherValue(value, allValues, setAllValues, options)
            setOtherIncluded(false)
        }

    }


    const toggleIncludeOther = () => {
        if (!editingOther && !otherIncluded) {
            addOtherValue(other, allValues, setAllValues, options)
            setOtherIncluded(true)
        }
        else {
            removeOtherValue(other, allValues, setAllValues, options)
            setOtherIncluded(false)
        }

    }



    return (
        <div className="verticalSection">
            {title !== null ? <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{title}</CustomText> : <div></div>}
            {infoText !== null ? <CustomText type={locCon.INFO_TEXT}>{infoText}</CustomText> : <div></div>}

            <div style={styles.checkboxLevel1Container} >
                {
                    options.map(k => <div key={k} style={styles.checkboxContainer}>
                        <CustomCheckBox disableClick={isDisabled} type={locCon.MULTIPLE_CHOICE_ANSWER_CHECKBOX} checked={!isNullOrUndefined(allValues) && allValues.includes(k)} onClick={(val) => setLocalValue(k, allValues, setAllValues)} />
                        <div style={styles.checkboxText}>
                            <CustomText type={isDisabled ? locCon.DISABLED_OPTION_TEXT : locCon.OPTION_TEXT} onClick={() => isDisabled ? null : setLocalValue(k, allValues, setAllValues)}>{optionsTextDict[k]}</CustomText>
                        </div>
                    </div>)
                }


                {
                    !includeOther
                        ? <div></div>
                        : isDisabled
                            ? <div style={{ height: "6.4vh" }}></div>
                            : <div style={{ ...styles.checkboxContainer }} >
                                <CustomCheckBox disableClick={editingOther} type={locCon.MULTIPLE_CHOICE_ANSWER_CHECKBOX} checked={otherIncluded} onClick={() => toggleIncludeOther()} />
                                <div style={{ marginTop: "0.2vh", width: "100%", marginRight: "1vh" }}>
                                    <SimpleStoreCopyField value={other} setValue={setOther} editingValue={editingOther} setEditingValue={setEditingOther} enableCopy={false} placeHolder={i18n.t("other")} saveCleanFunction={(isEditing, value) => toggleIncludeOtherAfterSave(isEditing, value)} />
                                </div>

                            </div>

                }

                <div style={{ ...styles.checkboxContainer, marginTop: "0.5vh" }}>
                    <CustomCheckBox disableClick={isDisabled} type={locCon.MULTIPLE_CHOICE_ANSWER_CHECKBOX} checked={areValuesEmpty} onClick={() => isDisabled ? null : toggleAreValuesEmpty()} />
                    <div style={styles.checkboxText}>
                        <CustomText type={isDisabled ? locCon.DISABLED_OPTION_TEXT : locCon.OPTION_TEXT} onClick={() => isDisabled ? null : toggleAreValuesEmpty()}>{noneOptionText}</CustomText>
                    </div>
                </div>
            </div>
            {includeCheckbox ? <CheckboxDefaultValue value={allValues} setValue={setAllValues} defaultValue={defaultValue} text={checkboxText} /> : <div></div>}
            {includeAnswer ? <CustomText type={locCon.INFO_TEXT} style={{ marginBottom: "1vh" }}>{`${i18n.t("answer")}: ${allValues === defaultValue ? defaultValue : isNullOrUndefined(allValues) ? "----" : allValues.length === 0 ? noneOptionText : allValues.map(v => v in optionsTextDict ? optionsTextDict[v] : v).join(", ")}`}</CustomText> : <div></div>}
        </div >


    )
}



const setLocalValue = (value, allValues, setAllValues) => {

    if (isNullOrUndefinedOrEmptyOrMissing(value))
        return

    let newAllSelectedValues = []
    if (!isNullOrUndefined(allValues))
        newAllSelectedValues = [...allValues]

    if (!newAllSelectedValues.includes(value))
        setAllValues([...newAllSelectedValues, value])
    else
        setAllValues(newAllSelectedValues.filter(k => k !== value))
}

const removeOtherValue = (value, allValues, setAllValues, options) => {

    if (isNullOrUndefinedOrEmptyOrMissing(value) || isNullOrUndefined(allValues))
        return

    let newAllSelectedValues = []

    if (allValues !== libCon.NA && !isNullOrUndefined(allValues))
        newAllSelectedValues = [...allValues.filter(v => options.includes(v))]



    setAllValues(newAllSelectedValues.filter(k => k !== value))

}


const addOtherValue = (value, allValues, setAllValues, options) => {

    if (isNullOrUndefinedOrEmptyOrMissing(value))
        return

    let newAllSelectedValues = []
    if (!isNullOrUndefined(allValues))
        newAllSelectedValues = [...allValues.filter(v => options.includes(v))]



    if (!newAllSelectedValues.includes(value))
        setAllValues([...newAllSelectedValues, value])

}




const styles = {

    checkboxContainer:
    {
        width: "100%",
        display: "flex",
        flexDirection: 'row',
        justifyContent: "flex-start",
        alignItems: 'flex-start',
        marginLeft: "1vh",
    },
    checkboxLevel1Container: {
        width: "100%",
        display: "flex",
        flexDirection: 'column',
        justifyContent: "flex-start",
        alignItems: 'flex-start',
        marginLeft: "3vh",
        marginBottom: "3vh"
    },
    checkboxText: {
        height: "80%",
        marginTop: "1.5vh",
        marginRight: "3vh"
    }
}

