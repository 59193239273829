import React, { useEffect, useState } from "react";
import Header from "../components/Header.js";
import CustomText from "../elements/CustomText.js";
//import { HasGovees, HasKestrels, RefBundleEnviromentalSensors } from "../hooks/BundleDeviceHooks";
import { RefLanguage } from "../hooks/LanguageHooks.js";
import * as libCon from '../community-hats-js-library/Constants.js';
import * as locCon from '../LocalConstants.js';
import CustomButton from "../elements/CustomButton.js";
import RandomEmailAccountGenerator from "../components/RandomEmailAccountGenerator.js";
import { ModalHook } from "../hooks/ModalHooks.js";
import CheckboxGroup from "../elements/CheckboxGroup.js";
import { SyncATOButton } from "../elements/SyncATOButton.js";
import { WearableSerialExtractorForBundleWearable } from "../components/WearableSerialExtractor.js";
import { SimpleCopyField, SimpleCopyFieldFromATO, SimpleCopyFieldFromId, SimpleStoreCopyFieldFromATO } from "../elements/SimpleStoreCopyField.js";
import TestFitibitApiButton from "../components/TestFitibitApiButton.js";
import { goToThirdPartyLink } from "../community-hats-js-library/utils/generalFunctions.js";
import { buildFitbitURL } from "../community-hats-js-library/utils/fitbitFunctions.js";
import PasteFitbitAPIValues from "../components/PasteFitbitAPIValues.js";
import { SensorSetViewer } from "../components/SensorSetViewer.js";
import { RefHasGovees, RefHasKestrels, RefBundleDevices } from "../hooks/BundleDeviceHooks.js";
import SimpleLink from "../elements/SimpleLink.js";
import ActivationCodeLoader from "../components/ActivationCodeLoader.js";
import TagGenerator from "../components/TagGenerator.js";
import { RefKeysOfATOSet, restartBundleSetUp, setStoredValue } from "../hooks/StoreHooks.js";
import { NewIcon } from "../elements/CustomIcons.js";
import CompletionStatusText from "../elements/CompletionStatusText.js";
import SensorInboxEmail from "../components/SensorInboxEmail.js";



export default function BundleSetUp() {

  const i18n = RefLanguage()



  // Modal
  const [modal, openModal] = ModalHook()

  // Checks each brand
  const hasGovees = RefHasGovees()
  const hasKestrel = RefHasKestrels()

  const [allCheckboxes, setAllCheckboxes] = useState([...locCon.BUNDLE_SET_UP_CHECKBOXES])

  const keys = RefKeysOfATOSet()

  useEffect(() => {

    let newCheckboxes = [...locCon.BUNDLE_SET_UP_CHECKBOXES, ...keys.map(positionId => locCon.getASCheckBoxId(positionId))]

    if (hasGovees)
      newCheckboxes = [...newCheckboxes, ...locCon.BUNDLE_SET_UP_GOVEE_CHECKBOXES]

    if (hasKestrel)
      newCheckboxes = [...newCheckboxes, ...locCon.BUNDLE_SET_UP_KESTREL_CHECKBOXES]

    setAllCheckboxes(newCheckboxes)

  }, [keys, hasGovees, hasKestrel])

  // Bundle Devices
  const bundleDevices = RefBundleDevices()


  const restartProcess = () => {


    openModal(i18n.t("restartProcess"), i18n.t("restartProcessLong"), [
      {
        text: i18n.t("cancel"),
        onClick: () => false,
        type: locCon.MODULE_CANCEL_BUTTON,
      },
      {
        text: i18n.t("restart"),
        onClick: () => restartBundleSetUp(),
        type: locCon.MODULE_REGULAR_BUTTON
      },
    ]);



  }


  const [copiedGoveeSerials, setCopiedGoveeSerials] = useState([])

  const goveeSeialCopied = (serial) => {


    let newCopiedSerials = copiedGoveeSerials
    if (!newCopiedSerials.includes(serial))
      newCopiedSerials.push(serial)


    let allSerials = bundleDevices.filter(s => s[libCon.FIELDS][libCon.ATF_BRAND] === libCon.GOVEE).map(s => s[libCon.FIELDS][libCon.ATF_SERIAL])

    if (allSerials.every(s => newCopiedSerials.includes(s)))
      setStoredValue(locCon.SUG_ADD_DEVICE_TO_APP, true)

    setCopiedGoveeSerials(newCopiedSerials)

  }


  return (
    <div className="mainAppContainer">
      <div className='pageContainer'>
        {modal}
        <Header long={false} />
        <CustomText type={locCon.MODULE_TITLE_TEXT}>{i18n.t("setUpBundle")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("stepsToSetUpBundle")}</CustomText>


        <CustomButton type={locCon.DANGER_BUTTON} onClick={() => restartProcess()}>{i18n.t("restartProcess")}</CustomButton>

        <div className="horizontalLine" />




        {/* Google Account*/}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("googleAccount")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("googleAccountText")}</CustomText>
        <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.GA_SET_UP_GOOGLE_ACCOUNT, [libCon.TEXT]: i18n.t("setUpGoogleAccount"), [libCon.CONTAINER]: <RandomEmailAccountGenerator includeCheckBox={true} /> }} />
        <div className="horizontalLine" />


        {/* Fitbit set up */}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("fitBitApplication")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("fitBitApplicationText")}</CustomText>
        <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.FTB_DOWNLOAD_APPLICATION, [libCon.TEXT]: i18n.t("downloadFitbitApp") }} />
        <CheckboxGroup mainCheckbox={{
          [libCon.ID]: locCon.FTB_CREATE_NEW_ACCOUNT, [libCon.TEXT]: i18n.t("createNewAccount")
        }} />
        <CheckboxGroup mainCheckbox={{
          [libCon.ID]: locCon.FTB_SET_TIME_ZONE, [libCon.TEXT]: i18n.t("setTimeZone"),
          [libCon.CONTAINER]: <SimpleCopyField title={i18n.t("fitbitTimeZone")} value={locCon.FITBIT_TIME_ZONE} />
        }} />
        <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.FTB_SET_UP_DEVICE, [libCon.TEXT]: i18n.t("setUpWearableDevice") }} />
        <CheckboxGroup mainCheckbox={{
          [libCon.ID]: locCon.AWP_GET_WEARABLE_SERIAL,
          [libCon.TEXT]: i18n.t("getWearableSerialNumber"),
          [libCon.CONTAINER]: <div style={{ marginBottom: 10 }}>
            <WearableSerialExtractorForBundleWearable enableCopy={false} />
          </div>
        }} />
        <CheckboxGroup disableClick={true} mainCheckbox={{
          [libCon.ID]: locCon.AWP_LINK_WEARABLE_SERIAL,
          [libCon.TEXT]: i18n.t("linkWearableSerialNumber"),
          [libCon.CONTAINER]: <SyncATOButton atoId={locCon.AT_OBJECT_WEARABLE} atoBehavior={libCon.ATO_BEHAVIOR_PULL} onClickCleanup={(val) => setStoredValue(locCon.AWP_LINK_WEARABLE_SERIAL, val)} />
        }} />




        <div className={"horizontalLine"} />


        {/* Fitbit API access*/}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("fitbitApiAccess")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("fitBitAPITextShort")}</CustomText>
        <CheckboxGroup
          mainCheckbox={{ [libCon.ID]: locCon.FTB_SET_API, [libCon.TEXT]: i18n.t("setUpFitbitAPI") }}
          checkBoxArray={[
            {
              [libCon.ID]: locCon.FTB_GO_TO_AUTHORIZATION_PAGE,
              [libCon.TEXT]: i18n.t("goToAuthPage"),
              [libCon.CONTAINER]: <div>
                <CustomButton type={locCon.PRIMARY_BUTTON} onClick={async () => { goToThirdPartyLink(buildFitbitURL(await libCon.CONFIG(libCon.FITBIT_FINAL_APPLICATION_ID))); setStoredValue(locCon.FTB_GO_TO_AUTHORIZATION_PAGE, true) }}>
                  {i18n.t("authorizeApp")}
                </CustomButton>
              </div>
            },
            {
              [libCon.ID]: locCon.FTB_COPY_TOKEN_AND_REQUEST,
              [libCon.TEXT]: <div><NewIcon size={18} />{i18n.t("copyTokenAndRequest")}</div>,
              [libCon.CONTAINER]: <div>
                <PasteFitbitAPIValues checkboxId={locCon.FTB_COPY_TOKEN_AND_REQUEST} />
                <SimpleCopyFieldFromId valueID={locCon.FITBIT_TOKEN} title={i18n.t("fitbitAccessToken")} />
                <SimpleCopyFieldFromId valueID={locCon.FITBIT_REQUEST_ID} title={i18n.t("fitbitRequestId")} />
              </div>
            },
            {
              [libCon.ID]: locCon.FTB_TEST_TOKEN,
              [libCon.TEXT]: <div>{i18n.t("testFitbitApi")}</div>,
              [libCon.CONTAINER]: <TestFitibitApiButton checkboxId={locCon.FTB_TEST_TOKEN} />
            },


          ]}
        />


        <div className="horizontalLine" />

        {/* Add Email to database*/}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("emailToDataBaseTitle")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("emailToDataBaseText")}</CustomText>
        <CheckboxGroup disableClick={true} mainCheckbox={{
          [libCon.ID]: locCon.GA_ADD_EMAIL_TO_DATABASE,
          [libCon.TEXT]: i18n.t("addEmailToDataBase"),
          [libCon.CONTAINER]: <SyncATOButton atoId={locCon.AT_OBJECT_EMAIL_ACCOUNT} atoBehavior={libCon.ATO_BEHAVIOR_UPDATE_CREATE} onClickCleanup={(val) => setStoredValue(locCon.GA_ADD_EMAIL_TO_DATABASE, val)} />
        }} />



        <div className="horizontalLine" />


        {/* Link Phone*/}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("linkPhonesSerialNumberTitle")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("linkPhoneSerialText")}</CustomText>
        <CheckboxGroup disableClick={true} mainCheckbox={{
          [libCon.ID]: locCon.APPD_LINK_PHONE_SERIAL,
          [libCon.TEXT]: i18n.t("linkPhonesSerialNumber"),
          [libCon.CONTAINER]: <div>
            <SimpleStoreCopyFieldFromATO atoId={locCon.AT_OBJECT_PHONE} fieldId={libCon.ATF_SERIAL} title={i18n.t("phoneSerialNumber")} placeHolder={i18n.t("serialNumber")} />
            <SyncATOButton atoId={locCon.AT_OBJECT_PHONE} atoBehavior={libCon.ATO_BEHAVIOR_PULL} onClickCleanup={(val) => setStoredValue(locCon.APPD_LINK_PHONE_SERIAL, val)} />
          </div>
        }} />

        <div className="horizontalLine" />

        {/* Location Tracker App*/}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("locationTrackerApp")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("locationTrackerAppText")}</CustomText>
        <CheckboxGroup
          mainCheckbox={{ [libCon.ID]: locCon.TA_SET_UP_TRACKER_APP, [libCon.TEXT]: i18n.t("setUpLocationTrackerApp") }}
          checkBoxArray={[
            { [libCon.ID]: locCon.TA_DOWNLOAD_TACKER_APP, [libCon.TEXT]: i18n.t("downloadAndInstallApplication") },
            { [libCon.ID]: locCon.TA_LOCATION_PERMISSIONS, [libCon.TEXT]: i18n.t("givePermissions") },
            {
              [libCon.ID]: locCon.TA_GET_PHONE_SERIAL,
              [libCon.TEXT]: i18n.t("enterPhonesSerialNumber"),
              [libCon.CONTAINER]: <SimpleCopyFieldFromATO atoId={locCon.AT_OBJECT_PHONE} fieldId={libCon.ATF_SERIAL} title={i18n.t("phoneSerialNumber")} />
            },
            { [libCon.ID]: locCon.TA_ACTIVATE_TRACKING, [libCon.TEXT]: i18n.t("activateTracking") },
            { [libCon.ID]: locCon.TA_TURN_ON_LOCATION, [libCon.TEXT]: i18n.t("turnOnLocation") },
            { [libCon.ID]: locCon.TA_CONFIGURE_BATTERY, [libCon.TEXT]: i18n.t("configureBatteryBehavior") },
          ]}
        />
        <div className="horizontalLine" />


        {/* Add devices to bundle*/}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("bundleDevices")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("bundleDevicesText")}</CustomText>

        <SensorSetViewer />


        <div className="horizontalLine" />



        {/* Sensor Setup*/}
        {/* Govee*/}
        {
          hasGovees
            ? <>
              <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("setUpGovees")}</CustomText>
              <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("GoveeSetUpSensorText")}</CustomText>
              <CheckboxGroup
                mainCheckbox={{ [libCon.ID]: locCon.SUG_SET_UP_SENSOR, [libCon.TEXT]: i18n.t("setUpGovees") }}
                checkBoxArray={[
                  {
                    [libCon.ID]: locCon.SUG_DOWNLOAD_APP,
                    [libCon.TEXT]: i18n.t("downloadGoveeApp"),
                    [libCon.CONTAINER]: <div>
                      <SimpleLink text={i18n.t("goveeApp")} link={"https://play.google.com/store/apps/details?id=com.govee.home&hl=en&gl=US"} onClickCleanup={() => setStoredValue(locCon.SUG_DOWNLOAD_APP, true)} />
                    </div>
                  },
                  {
                    [libCon.ID]: locCon.SUG_CREATE_ACCOUNT,
                    [libCon.TEXT]: i18n.t("createGoveeAccount"),
                    [libCon.CONTAINER]: <div>
                      <SimpleCopyFieldFromATO atoId={locCon.AT_OBJECT_EMAIL_ACCOUNT} fieldId={libCon.ATF_EMAIL} title={i18n.t("goveeEmail")} processFunction={(val) => val === null || val === undefined ? null : val} />
                      <SimpleCopyFieldFromATO atoId={locCon.AT_OBJECT_EMAIL_ACCOUNT} fieldId={libCon.ATF_GOVEE_PASSWORD} title={i18n.t("goveePassword")} onCopyCleanUp={() => setStoredValue(locCon.SUG_CREATE_ACCOUNT, true)} />
                    </div>
                  },
                  {
                    [libCon.ID]: locCon.SUG_ADD_DEVICE_TO_APP,
                    [libCon.TEXT]: i18n.t("addSensorsToApp"),
                    [libCon.CONTAINER]: <div className="verticalSection">
                      <CustomText type={locCon.INFO_TEXT}>{i18n.t("nameGoveeSensors")}</CustomText>
                      {
                        bundleDevices.filter(s => s[libCon.FIELDS][libCon.ATF_BRAND] === libCon.GOVEE).map((s, i) =>
                          <SimpleCopyField key={`${s[libCon.FIELDS][libCon.ATF_SERIAL]}_${i}`} value={s[libCon.FIELDS][libCon.ATF_SERIAL]} onCopyCleanUp={() => goveeSeialCopied(s[libCon.FIELDS][libCon.ATF_SERIAL])} />
                        )
                      }
                    </div>
                  },
                  {
                    [libCon.ID]: locCon.SUG_ADD_INBOX_SENSOR,
                    [libCon.TEXT]: i18n.t("addInboxSensor"),
                    [libCon.CONTAINER]: <div>
                      <SensorInboxEmail />
                      <ActivationCodeLoader onCopyCleanUp={() => setStoredValue(locCon.SUG_ADD_INBOX_SENSOR, true)} />
                    </div>
                  },
                ]}
              />
              <div className="horizontalLine" />

            </>
            : <div></div>
        }




        {/* Kestrel*/}
        {
          hasKestrel
            ? <>
              <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("setUpKestrel")}</CustomText>
              <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("KestrelSetUpSensorText")}</CustomText>

              <CheckboxGroup
                mainCheckbox={{ [libCon.ID]: locCon.SUK_SET_UP_SENSOR, [libCon.TEXT]: i18n.t("setUpKestrel") }}
                checkBoxArray={[
                  {
                    [libCon.ID]: locCon.SUK_DOWNLOAD_APP,
                    [libCon.TEXT]: i18n.t("downloadSensorApp"),
                    [libCon.CONTAINER]: <div>
                      <SimpleLink text={i18n.t("kestrelApp")} link={"https://play.google.com/store/apps/details?id=com.kestrelinstruments.kdex&hl=en&gl=US"} onClickCleanup={() => setStoredValue(locCon.SUK_DOWNLOAD_APP, true)} />
                    </div>
                  },
                  {
                    [libCon.ID]: locCon.SUK_ADD_DEVICE_TO_APP,
                    [libCon.TEXT]: i18n.t("addSensorToApp")
                  },
                  {
                    [libCon.ID]: locCon.SUK_DISABLE_AUTOCONNECT,
                    [libCon.TEXT]: i18n.t("disableAutoConnect")
                  },
                  {
                    [libCon.ID]: locCon.SUK_SET_LOG_INTERVAL,
                    [libCon.TEXT]: i18n.t("setLogInterval")
                  },

                ]} />
              <div className="horizontalLine" />
            </>
            : <div></div>

        }



        {/* Group in folder */}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("groupInFolder")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("groupInFolderText")}</CustomText>
        <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.GAF_GROUP_APPS_IN_FOLDER, [libCon.TEXT]: i18n.t("groupInFolderLong") }} />
        <div className="horizontalLine" />




        {/* Tag Bundle */}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("tagBundleTitle")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("tagBundleText")}</CustomText>
        <TagGenerator />
        <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.TB_TAG_BUNDLE, [libCon.TEXT]: i18n.t("tagBundle") }} />
        <div className="horizontalLine" />


        {/* Add / Sync Bundle to database*/}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("bundleToDataBaseTitle")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("bundleToDataBaseText")}</CustomText>
        <CheckboxGroup disableClick={true} mainCheckbox={{
          [libCon.ID]: locCon.BD_ADD_BUNDLE_TO_DATABASE,
          [libCon.TEXT]: i18n.t("addBundleToDataBase"),
          [libCon.CONTAINER]: <SyncATOButton atoId={locCon.AT_OBJECT_BUNDLE} atoBehavior={libCon.ATO_BEHAVIOR_UPDATE_CREATE} onClickCleanup={(val) => setStoredValue(locCon.BD_ADD_BUNDLE_TO_DATABASE, val)} />
        }} />

        <div className="horizontalLine" />

        <CompletionStatusText checkBoxIds={allCheckboxes} />

        <div className="horizontalLine" />



      </div>
    </div>);
}








