import Header from '../components/Header';
import * as locCon from '../LocalConstants';
import * as libCon from '../community-hats-js-library/Constants';
import CustomText from '../elements/CustomText';
import { RefLanguage } from '../hooks/LanguageHooks';
import { SimpleCopyField, SimpleCopyFieldFromATO, SimpleStoreCopyFieldFromATO } from '../elements/SimpleStoreCopyField';
import { BundleSyncProgress, SyncATOButton } from '../elements/SyncATOButton';
import { addNewPositionToATOSet, getATOFields, RefATObjectId, RefATObjectStatus, RefATOField, restartBundleForSync, setATObject, setATObjectInsideSet, setATOField, setATOFieldInsideSet, setMultipleCheckBoxes, setStoredValue } from '../hooks/StoreHooks';
import { CustomDangerButtonWithDisability } from '../elements/CustomButton';
import { filterObjectByFunction, isNullOrUndefined, isNullOrUndefinedOrEmpty } from '../community-hats-js-library/utils/generalFunctions';
import { useState } from 'react';
import { getPhonePlacementByPhoneSerial, getPlacements } from '../community-hats-js-library/utils/airtableFunctions';
import { syncRecord } from '../utils/airtableFunctions';
import { ModalHook } from '../hooks/ModalHooks';
import { DeviceStatusReducerHook, hasGovees, hasKestrels, RefHasGovees, RefHasKestrels } from '../hooks/BundleDeviceHooks';
import { SimpleRadioButton } from '../elements/SimpleRadioButton';
import { useEffect } from 'react';
import CheckboxGroup from '../elements/CheckboxGroup';
import SimpleLink from '../elements/SimpleLink';
import CompletionStatusText from '../elements/CompletionStatusText';


export function BundleSync() {


  // Language
  const i18n = RefLanguage()

  // Modal
  const [modal, openModal] = ModalHook()

  // Mesage
  const [messages, setMessages] = useState([])

  // Checks each brand
  const bundleHasGovees = RefHasGovees()
  const bundleHasKestrel = RefHasKestrels()

  // Phone
  const phoneId = RefATObjectId(locCon.AT_OBJECT_PHONE)
  const phoneStatus = RefATObjectStatus(locCon.AT_OBJECT_PHONE)
  const phoneSerial = RefATOField(locCon.AT_OBJECT_PHONE, libCon.ATF_SERIAL)


  const [objectStatus, updateAll, updateSingle] = DeviceStatusReducerHook()

  const [databaseObjectsStatus, setDatabaseObjectsStatus] = useState({})
  const [databasePlacementStatus, setDatabasePlacementStatus] = useState([])

  const startAll = () => updateAll(libCon.STATUS_LOADING)
  const allError = () => updateAll(libCon.STATUS_ERROR)

  const [syncSuccessfull, setSyncSuccessfull] = useState(false)

  // For the SetUp
  const [allCheckboxes, setAllCheckboxes] = useState([])


  const syncBundleLocal = async () => {


    // Used variables
    var wearableId, accountId, houseId, status, message, objectId, fields, bundleFields, phonePlacementFields, participantId, records, sensorStatus, houseFields, placementStatus;
    var sensorIds = [], positionIds = [], sensorFields = []
    var participantFields = {}
    var localStatus = {}


    // Starts
    setMessages([])
    let localMessages = []
    restartBundleForSync();
    startAll();

    // Phone Placement
    // Looks for the phone placement (to get the participant)
    [status, records] = await getPhonePlacementByPhoneSerial(phoneSerial);


    if (status === libCon.OK) {


      // Gets only active
      records = filterObjectByFunction(records, (val) => val[libCon.ATF_ACTIVE] === libCon.YES);

      if (Object.keys(records).length > 0) {

        updateSingle(locCon.AT_OBJECT_PHONE_PLACEMENT, libCon.STATUS_OK, localStatus);

        objectId = Object.keys(records)[0];
        phonePlacementFields = records[objectId];

        setATObject(locCon.AT_OBJECT_PHONE_PLACEMENT, objectId, phonePlacementFields);

        participantId = isNullOrUndefinedOrEmpty(phonePlacementFields[libCon.ATF_PARTICIPANT]) ? null : phonePlacementFields[libCon.ATF_PARTICIPANT][0];

      }
      else
        updateSingle(locCon.AT_OBJECT_PHONE_PLACEMENT, libCon.STATUS_NOT_FOUND, localStatus);


    }
    else
      updateSingle(locCon.AT_OBJECT_PHONE_PLACEMENT, libCon.STATUS_NOT_FOUND, localStatus);


    // Participant
    // If not null, synchs the participant
    if (!isNullOrUndefined(participantId)) {


      [status, message, objectId, participantFields] = await syncRecord({
        tableId: await libCon.CONFIG(locCon.AT_OBJECTS_CONFIGS[locCon.AT_OBJECT_PARTICIPANT][libCon.TABLE_CONFIG_KEY]),
        behavior: libCon.ATO_BEHAVIOR_PULL,
        id: participantId,
        searchField: null,
        searchValue: null
      });



      if (status === libCon.OK) {
        updateSingle(locCon.AT_OBJECT_PARTICIPANT, libCon.STATUS_OK, localStatus);
        setATObject(locCon.AT_OBJECT_PARTICIPANT, objectId, participantFields);

      }
      else
        updateSingle(locCon.AT_OBJECT_PARTICIPANT, libCon.STATUS_NOT_FOUND, localStatus);

    }
    else
      updateSingle(locCon.AT_OBJECT_PARTICIPANT, libCon.STATUS_NOT_FOUND, localStatus);

    // House
    // Participant Id not null, searches
    houseId = isNullOrUndefinedOrEmpty(participantFields[libCon.ATF_ACTIVE_HOUSE]) ? null : participantFields[libCon.ATF_ACTIVE_HOUSE][0]
    if (!isNullOrUndefined(houseId)) {
      [status, message, objectId, houseFields] = await syncRecord({
        tableId: await libCon.CONFIG(locCon.AT_OBJECTS_CONFIGS[locCon.AT_OBJECT_HOUSE][libCon.TABLE_CONFIG_KEY]),
        behavior: libCon.ATO_BEHAVIOR_PULL,
        id: houseId,
        searchField: null,
        searchValue: null
      });

      if (status === libCon.OK) {
        updateSingle(locCon.AT_OBJECT_HOUSE, libCon.STATUS_OK, localStatus);
        setATObject(locCon.AT_OBJECT_HOUSE, objectId, houseFields);

      }
      else
        updateSingle(locCon.AT_OBJECT_HOUSE, libCon.STATUS_NOT_FOUND, localStatus);

    }
    else
      updateSingle(locCon.AT_OBJECT_HOUSE, libCon.STATUS_NOT_FOUND, localStatus);






    // Bundle
    // ----------
    // Looks for bundle

    [status, message, objectId, bundleFields] = await syncRecord({
      tableId: await libCon.CONFIG(locCon.AT_OBJECTS_CONFIGS[locCon.AT_OBJECT_BUNDLE][libCon.TABLE_CONFIG_KEY]),
      behavior: libCon.ATO_BEHAVIOR_PULL,
      id: null,
      searchField: [libCon.ATF_PHONE_SERIAL],
      searchValue: [phoneSerial]
    });


    let bundleInDatabase = true
    // Bundle not in database
    // Will reconstruct it from placements
    if (status === libCon.NOT_FOUND_ERROR) {

      bundleInDatabase = false

      // Looks for the ids in the placement
      if (isNullOrUndefined(participantId)) {
        allError()
        setMessages(i18n.t("bundleNotActiveMessage"))
        return
      }

      accountId = isNullOrUndefinedOrEmpty(participantFields[libCon.ATF_ACTIVE_ACCOUNT_EMAIL]) ? null : participantFields[libCon.ATF_ACTIVE_ACCOUNT_EMAIL][0];
      wearableId = isNullOrUndefinedOrEmpty(participantFields[libCon.ATF_ACTIVE_WEARABLE]) ? null : participantFields[libCon.ATF_ACTIVE_WEARABLE][0];
      sensorIds = isNullOrUndefinedOrEmpty(houseFields[libCon.ATF_ACTIVE_SENSORS]) ? [] : houseFields[libCon.ATF_ACTIVE_SENSORS];

    }
    // Bundle in Database
    // Will Pull it    
    else {

      // Sets Bundle
      updateSingle(locCon.AT_OBJECT_BUNDLE, libCon.STATUS_OK, localStatus);
      setATObject(locCon.AT_OBJECT_BUNDLE, objectId, bundleFields);

      accountId = isNullOrUndefinedOrEmpty(bundleFields[libCon.ATF_ACCOUNT_EMAIL]) ? null : bundleFields[libCon.ATF_ACCOUNT_EMAIL][0];
      wearableId = isNullOrUndefinedOrEmpty(bundleFields[libCon.ATF_WEARABLE]) ? null : bundleFields[libCon.ATF_WEARABLE][0];
      sensorIds = isNullOrUndefinedOrEmpty(bundleFields[libCon.ATF_SENSORS]) ? [] : bundleFields[libCon.ATF_SENSORS];

    }

    // Account
    if (isNullOrUndefinedOrEmpty(accountId))
      updateSingle(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.STATUS_NOT_FOUND, localStatus);
    else {
      [status, message, objectId, fields] = await syncRecord({
        tableId: await libCon.CONFIG(locCon.AT_OBJECTS_CONFIGS[locCon.AT_OBJECT_EMAIL_ACCOUNT][libCon.TABLE_CONFIG_KEY]),
        behavior: libCon.ATO_BEHAVIOR_PULL,
        id: accountId,
        searchField: null,
        searchValue: null
      });

      if (status === libCon.OK) {
        updateSingle(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.STATUS_OK, localStatus);
        setATObject(locCon.AT_OBJECT_EMAIL_ACCOUNT, objectId, fields);
      }
      else
        updateSingle(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.STATUS_ERROR, localStatus);

    }



    // Wearable
    if (isNullOrUndefinedOrEmpty(wearableId))
      updateSingle(locCon.AT_OBJECT_WEARABLE, libCon.STATUS_NOT_FOUND, localStatus);
    else {
      [status, message, objectId, fields] = await syncRecord({
        tableId: await libCon.CONFIG(locCon.AT_OBJECTS_CONFIGS[locCon.AT_OBJECT_WEARABLE][libCon.TABLE_CONFIG_KEY]),
        behavior: libCon.ATO_BEHAVIOR_PULL,
        id: wearableId,
        searchField: null,
        searchValue: null
      });

      if (status === libCon.OK) {
        updateSingle(locCon.AT_OBJECT_WEARABLE, libCon.STATUS_OK, localStatus);
        setATObject(locCon.AT_OBJECT_WEARABLE, objectId, fields);
      }
      else
        updateSingle(locCon.AT_OBJECT_WEARABLE, libCon.STATUS_ERROR, localStatus);

    }

    // Sensors
    if (isNullOrUndefinedOrEmpty(sensorIds))
      updateSingle(locCon.AT_OBJECT_SENSOR, libCon.STATUS_NOT_FOUND, localStatus);
    else {

      sensorStatus = await Promise.all(sensorIds.map(async sensorId => {

        [status, message, objectId, fields] = await syncRecord({
          tableId: await libCon.CONFIG(locCon.AT_OBJECTS_CONFIGS[locCon.AT_OBJECT_SENSOR][libCon.TABLE_CONFIG_KEY]),
          behavior: libCon.ATO_BEHAVIOR_PULL,
          id: sensorId,
          searchField: null,
          searchValue: null
        });

        if (status === libCon.OK) {

          let positionId = addNewPositionToATOSet()
          setATObjectInsideSet(positionId, locCon.AT_OBJECT_SENSOR, objectId, fields);
          positionIds.push(positionId);
          sensorFields.push(fields);

          return libCon.STATUS_OK
        }
        else
          return libCon.STATUS_ERROR

      }))


      if (sensorStatus.every(status => status === libCon.STATUS_OK))
        updateSingle(locCon.AT_OBJECT_SENSOR, libCon.STATUS_OK, localStatus);
      else
        updateSingle(locCon.AT_OBJECT_SENSOR, libCon.STATUS_ERROR, localStatus);

    }


    // Checks if needs to add bundle
    if (!bundleInDatabase) {
      // Adds tag and color
      setATOField(locCon.AT_OBJECT_BUNDLE, libCon.ATF_TAG_CODE, phonePlacementFields[libCon.ATF_TAG_CODE]);
      setATOField(locCon.AT_OBJECT_BUNDLE, libCon.ATF_TAG_COLOR, phonePlacementFields[libCon.ATF_TAG_COLOR]);

      // Sets Phone
      setATOField(locCon.AT_OBJECT_BUNDLE, libCon.ATF_PHONE, [phoneId]);

      [status, message, objectId, fields] = await syncRecord({
        tableId: await libCon.CONFIG(locCon.AT_OBJECTS_CONFIGS[locCon.AT_OBJECT_BUNDLE][libCon.TABLE_CONFIG_KEY]),
        behavior: libCon.ATO_BEHAVIOR_CREATE,
        fields: getATOFields(locCon.AT_OBJECT_BUNDLE),
        id: null,
        searchField: null,
        searchValue: null
      });



      if (status === libCon.OK && !isNullOrUndefined(message)) {
        setATObject(locCon.AT_OBJECT_BUNDLE, objectId, fields)
        updateSingle(locCon.AT_OBJECT_BUNDLE, libCon.STATUS_OK, localStatus);

      }
      else
        updateSingle(locCon.AT_OBJECT_BUNDLE, libCon.STATUS_ERROR, localStatus);

    }


    // Placements
    // ----------
    // Participant Placement
    [status, records] = await getPlacements(await libCon.CONFIG(libCon.AT_TABLE_PARTICIPANTS_PLACEMENT), participantFields[libCon.ATF_PARTICIPANT_PLACEMENT]);
    if (status === libCon.NOT_FOUND_ERROR) {
      updateSingle(locCon.AT_OBJECT_PARTICIPANT_PLACEMENT, libCon.STATUS_NOT_FOUND, localStatus);

    }
    else if (status === libCon.OK && Object.keys(records).length > 0) {
      updateSingle(locCon.AT_OBJECT_PARTICIPANT_PLACEMENT, libCon.STATUS_OK, localStatus);
      setATObject(locCon.AT_OBJECT_PARTICIPANT_PLACEMENT, Object.keys(records)[0], Object.values(records)[0]);
    }
    else
      updateSingle(locCon.AT_OBJECT_PARTICIPANT_PLACEMENT, libCon.STATUS_ERROR, localStatus);

    // Phone Placement
    if (localStatus[locCon.AT_OBJECT_PHONE_PLACEMENT] === libCon.STATUS_NOT_FOUND) {
      setATOField(locCon.AT_OBJECT_PHONE_PLACEMENT, libCon.ATF_TAG_CODE, bundleFields[libCon.ATF_TAG_CODE]);
      setATOField(locCon.AT_OBJECT_PHONE_PLACEMENT, libCon.ATF_TAG_COLOR, bundleFields[libCon.ATF_TAG_COLOR]);
      setATOField(locCon.AT_OBJECT_PHONE_PLACEMENT, libCon.ATF_PHONE, [phoneId]);
    }

    // Wearable Placement
    [status, records] = await getPlacements(await libCon.CONFIG(libCon.AT_TABLE_WEARABLE_PLACEMENTS), participantFields[libCon.ATF_WEARABLE_PLACEMENT]);

    if (status === libCon.NOT_FOUND_ERROR) {
      setATOField(locCon.AT_OBJECT_WEARABLE_PLACEMENT, libCon.ATF_TAG_CODE, bundleFields[libCon.ATF_TAG_CODE]);
      setATOField(locCon.AT_OBJECT_WEARABLE_PLACEMENT, libCon.ATF_TAG_COLOR, bundleFields[libCon.ATF_TAG_COLOR]);
      updateSingle(locCon.AT_OBJECT_WEARABLE_PLACEMENT, libCon.STATUS_NOT_FOUND, localStatus);

    }
    else if (status === libCon.OK && Object.keys(records).length > 0) {
      updateSingle(locCon.AT_OBJECT_WEARABLE_PLACEMENT, libCon.STATUS_OK, localStatus);
      setATObject(locCon.AT_OBJECT_WEARABLE_PLACEMENT, Object.keys(records)[0], Object.values(records)[0]);
    }
    else
      updateSingle(locCon.AT_OBJECT_WEARABLE_PLACEMENT, libCon.STATUS_ERROR, localStatus);


    // Sensor Placements
    placementStatus = await Promise.all(positionIds.map(async (positionId, i) => {


      [status, records] = await getPlacements(await libCon.CONFIG(libCon.AT_TABLE_SENSOR_HOUSE_PLACEMENT), sensorFields[i][libCon.ATF_SENSOR_PLACEMENT]);


      if (status === libCon.NOT_FOUND_ERROR) {

        setATOFieldInsideSet(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_TAG_CODE, bundleFields[libCon.ATF_TAG_CODE]);
        setATOFieldInsideSet(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_TAG_COLOR, bundleFields[libCon.ATF_TAG_COLOR]);
        return (libCon.STATUS_NOT_FOUND);

      }
      else if (status === libCon.OK && Object.keys(records).length > 0) {

        setATObjectInsideSet(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, Object.keys(records)[0], Object.values(records)[0])
        return (libCon.STATUS_OK);
      }
      else
        return (libCon.STATUS_ERROR);

    }))

    if (placementStatus.length === 0 || placementStatus.some(status => status === libCon.STATUS_NOT_FOUND))
      updateSingle(locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.STATUS_NOT_FOUND, localStatus);
    else if (placementStatus.every(status => status === libCon.STATUS_OK))
      updateSingle(locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.STATUS_OK, localStatus);
    else
      updateSingle(locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.STATUS_ERROR, localStatus);




    // Checkboxes
    //  ------------------

    let checkBoxes = []

    // Checks if bundle has been deployed completely
    let deploymentComplete = [locCon.AT_OBJECT_PARTICIPANT_PLACEMENT,
    locCon.AT_OBJECT_PHONE_PLACEMENT,
    locCon.AT_OBJECT_WEARABLE_PLACEMENT,
    locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT].every(s => localStatus[s] === libCon.STATUS_OK)

    // Email Account
    if (localStatus[locCon.AT_OBJECT_EMAIL_ACCOUNT] === libCon.STATUS_OK) {
      checkBoxes.push(locCon.FTB_SET_API)
      checkBoxes.push(locCon.FTB_GO_TO_AUTHORIZATION_PAGE)
      checkBoxes.push(locCon.FTB_COPY_TOKEN_AND_REQUEST)
      checkBoxes.push(locCon.FTB_TEST_TOKEN)
      checkBoxes.push(locCon.GA_ADD_EMAIL_TO_DATABASE)

    }

    // Wearable
    if (localStatus[locCon.AT_OBJECT_WEARABLE] === libCon.STATUS_OK) {
      checkBoxes.push(locCon.AWP_GET_WEARABLE_SERIAL)
      checkBoxes.push(locCon.AWP_LINK_WEARABLE_SERIAL)
      checkBoxes.push(locCon.AWP_LINK_WEARABLE_SERIAL)

    }

    // Phone
    checkBoxes.push(locCon.APPD_LINK_PHONE_SERIAL)

    // Lccation Tracker
    if (deploymentComplete) {
      checkBoxes.push(locCon.LTA_RESTART_TRACKING_APP)
      checkBoxes.push(locCon.LTA_TURN_ON_LOCATION)
    }


    // Sensors 
    if (localStatus[locCon.AT_OBJECT_SENSOR] === libCon.STATUS_OK) {
      positionIds.forEach((positionId, i) => {
        checkBoxes.push(locCon.getASCheckBoxId(positionId))
        if (sensorFields[i][libCon.ATF_BRAND] === libCon.GOVEE) {
          checkBoxes.push(locCon.SUG_ADD_INBOX_SENSOR)
          checkBoxes.push(locCon.SUG_ADD_DEVICE_TO_APP)
        }

      }

      )
    }

    // Bundle
    if (localStatus[locCon.AT_OBJECT_BUNDLE] === libCon.STATUS_OK) {

      checkBoxes.push(locCon.TB_TAG_BUNDLE)
      checkBoxes.push(locCon.BD_ADD_BUNDLE_TO_DATABASE)
    }


    // Placements
    // Participant
    if (localStatus[locCon.AT_OBJECT_PARTICIPANT] === libCon.STATUS_OK) {
      checkBoxes.push(locCon.APT_ADD_PARTICIPANT_TO_DATABASE)
    }

    // Phone Placement
    if (localStatus[locCon.AT_OBJECT_PHONE_PLACEMENT] === libCon.STATUS_OK) {
      checkBoxes.push(locCon.APPD_PHONE_PLACEMENT)
    }


    // Wearable Placement
    if (localStatus[locCon.AT_OBJECT_WEARABLE_PLACEMENT] === libCon.STATUS_OK) {
      checkBoxes.push(locCon.AWP_WEARABLE_PLACEMENT)
    }

    // House
    if (localStatus[locCon.AT_OBJECT_HOUSE] === libCon.STATUS_OK) {
      checkBoxes.push(locCon.ELC_EXTRACT_LOCATIONS_COORDINATES)
      checkBoxes.push(locCon.ALD_ADD_LOCATION_TO_DATABASE)
    }

    if (localStatus[locCon.AT_OBJECT_PARTICIPANT_PLACEMENT] === libCon.STATUS_OK) {
      checkBoxes.push(locCon.APAR_PARTICIPANT_PLACEMENT)
    }

    // Sensor Placement
    positionIds.forEach((positionId, i) => {
      if (placementStatus[i] === libCon.STATUS_OK) {
        checkBoxes.push(locCon.getASHPCheckBoxId(positionId))
      }
    })


    // SIM, Duties, devices and collect
    if (deploymentComplete) {
      checkBoxes.push(locCon.IS_INSERT_SIM)
      checkBoxes.push(locCon.ED_EXPLAIN_FITBIT)
      checkBoxes.push(locCon.ED_EXPLAIN_DUTIES)
      checkBoxes.push(locCon.GD_SHOW_PHONE_PIN)
      checkBoxes.push(locCon.GD_GIVE_DEVICES)
      checkBoxes.push(locCon.CC_COLLECT_DATA)

    }



    setMultipleCheckBoxes(checkBoxes, true)

    // TODO
    // Add section for setting up:
    // Sensor types
    // Location Tracker

    // Set messages depending on what is missing and account for if deployment is missing


    localMessages.push(i18n.t("bundleSynchedCorrectly"))

    console.log(messages)

    setSyncSuccessfull(Object.values(localStatus).every(s => s !== libCon.STATUS_ERROR))

    setMessages(localMessages)

    setDatabaseObjectsStatus({ ...localStatus })
    setDatabasePlacementStatus([...placementStatus])

  }


  const confirmProcess = () => {


    openModal(i18n.t("bundleSync"), i18n.t("bundleSyncLong"), [
      {
        text: i18n.t("cancel"),
        onClick: () => false,
        type: locCon.MODULE_CANCEL_BUTTON,
      },
      {
        text: i18n.t("yes"),
        onClick: () => syncBundleLocal(),
        type: locCon.MODULE_REGULAR_BUTTON
      },
    ]);



  }


  // User deleted
  const [userDeleted, setUserDeleted] = useState(null)

  useEffect(() => {

    let checkBoxes = []

    // Email Account
    if (databaseObjectsStatus[locCon.AT_OBJECT_EMAIL_ACCOUNT] === libCon.STATUS_OK) {
      checkBoxes.push(locCon.GA_SET_UP_GOOGLE_ACCOUNT)
    }

    // Wearable
    if (databaseObjectsStatus[locCon.AT_OBJECT_WEARABLE] === libCon.STATUS_OK) {
      checkBoxes.push(locCon.FTB_DOWNLOAD_APPLICATION)
      checkBoxes.push(locCon.FTB_CREATE_NEW_ACCOUNT)
      checkBoxes.push(locCon.FTB_SET_TIME_ZONE)
      checkBoxes.push(locCon.FTB_SET_UP_DEVICE)
    }


    // Phone
    checkBoxes.push(locCon.TA_SET_UP_TRACKER_APP)
    checkBoxes.push(locCon.TA_DOWNLOAD_TACKER_APP)
    checkBoxes.push(locCon.TA_LOCATION_PERMISSIONS)
    checkBoxes.push(locCon.TA_GET_PHONE_SERIAL)
    checkBoxes.push(locCon.TA_ACTIVATE_TRACKING)
    checkBoxes.push(locCon.TA_TURN_ON_LOCATION)
    checkBoxes.push(locCon.TA_CONFIGURE_BATTERY)



    // Set sensorset up checkboxes

    if (hasGovees()) {

      checkBoxes.push(locCon.SUG_SET_UP_SENSOR)
      checkBoxes.push(locCon.SUG_DOWNLOAD_APP)
      checkBoxes.push(locCon.SUG_CREATE_ACCOUNT)
    }

    if (hasKestrels()) {

      checkBoxes.push(locCon.SUK_SET_UP_SENSOR)
      checkBoxes.push(locCon.SUK_DOWNLOAD_APP)
      checkBoxes.push(locCon.SUK_ADD_DEVICE_TO_APP)
      checkBoxes.push(locCon.SUK_SET_LOG_INTERVAL)
      checkBoxes.push(locCon.SUK_DISABLE_AUTOCONNECT)
    }

    // Extras
    checkBoxes.push(locCon.GAF_GROUP_APPS_IN_FOLDER)


    setAllCheckboxes([...checkBoxes])


    if (userDeleted === false) {
      // Set sensorset up checkboxes
      setMultipleCheckBoxes(checkBoxes, true)
    }
    else if (userDeleted === true) {
      setMultipleCheckBoxes(checkBoxes, false)
    }
  }, [userDeleted, databaseObjectsStatus, databasePlacementStatus])



  return (
    <div className="mainAppContainer">
      <div className='pageContainer'>
        {modal}

        <Header long={false} hideTitle={true} />
        <CustomText type={locCon.MODULE_TITLE_TEXT}>{i18n.t("bundleSync")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("stepsToSync")}</CustomText>

        <div className="horizontalLine" />


        <SimpleStoreCopyFieldFromATO atoId={locCon.AT_OBJECT_PHONE} fieldId={libCon.ATF_SERIAL} title={i18n.t("phoneSerialNumber")} placeHolder={i18n.t("serialNumber")} />
        <SyncATOButton atoId={locCon.AT_OBJECT_PHONE} atoBehavior={libCon.ATO_BEHAVIOR_PULL} />

        <div className="horizontalLine" />

        <CustomDangerButtonWithDisability isDisabled={isNullOrUndefined(phoneId) || phoneStatus !== locCon.ATO_UP_TO_DATE} title={i18n.t("pleaseSyncPhone")} onClick={() => confirmProcess()}>{i18n.t("bundleSync")}</CustomDangerButtonWithDisability>

        <div className='horizontalSection'>
          <div className='verticalSectionTop'>
            <BundleSyncProgress text={i18n.t("emailAccount")} status={objectStatus[locCon.AT_OBJECT_EMAIL_ACCOUNT]} />
            <BundleSyncProgress text={i18n.t("wearable")} status={objectStatus[locCon.AT_OBJECT_WEARABLE]} />
            <BundleSyncProgress text={i18n.t("bundle")} status={objectStatus[locCon.AT_OBJECT_BUNDLE]} />
            <BundleSyncProgress text={i18n.t("sensors")} status={objectStatus[locCon.AT_OBJECT_SENSOR]} />
            <BundleSyncProgress text={i18n.t("participant")} status={objectStatus[locCon.AT_OBJECT_PARTICIPANT]} />
          </div>

          <div className='verticalSectionTop'>
            <BundleSyncProgress text={i18n.t("house")} status={objectStatus[locCon.AT_OBJECT_HOUSE]} />
            <BundleSyncProgress text={i18n.t("participantPlacement")} status={objectStatus[locCon.AT_OBJECT_PARTICIPANT_PLACEMENT]} />
            <BundleSyncProgress text={i18n.t("wearablePlacement")} status={objectStatus[locCon.AT_OBJECT_WEARABLE_PLACEMENT]} />
            <BundleSyncProgress text={i18n.t("phonePlacement")} status={objectStatus[locCon.AT_OBJECT_PHONE_PLACEMENT]} />
            <BundleSyncProgress text={i18n.t("sensorHausePlacement")} status={objectStatus[locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT]} />
          </div>
        </div>

        {/* <div className='verticalSection' style={{ width: "80%", marginTop: "0.5vh", height: "1v.5h" }}>
          {
            messages.map((mess, k) => <CustomText key={k} type={locCon.INFO_TEXT} style={{ marginBottom: "1vh" }}>{mess}</CustomText>)
          }
        </div> */}

        <div className='horizontalLine' />

        {
          syncSuccessfull
            ? <div className='verticalSection' style={{ marginBottom: "2vh" }}>
              <SimpleRadioButton value={userDeleted} setValue={setUserDeleted} includeAnswer={false} options={[true, false]} optionsTextDict={{ [true]: i18n.t("yes"), [false]: i18n.t("no") }} infoText={i18n.t("didParticipantDeleteAllApps")} />
            </div>
            : <div></div>
        }

        {
          isNullOrUndefined(userDeleted)
            ? <div></div>
            : userDeleted === false
              ?
              <div className='verticalSection' style={{ width: "80%", marginTop: "0.5vh" }}>
                <CustomText type={locCon.SUCCESS_TEXT} style={{ marginBottom: "1vh" }}>{i18n.t("bundleSynchedCorrectly")}</CustomText>
              </div>
              : <div className='verticalSection'>
                <CustomText type={locCon.MODULE_TITLE_TEXT}>{i18n.t("setUpBundle")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("finishSettingUpBundle")}</CustomText>

                <div className='horizontalLine' />


                {/* Google Account*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("googleAccount")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("googleAccountText")}</CustomText>
                <CheckboxGroup disableInfoLink={true} mainCheckbox={{
                  [libCon.ID]: locCon.GA_SET_UP_GOOGLE_ACCOUNT,
                  [libCon.TEXT]: i18n.t("loginToGoogleAccount"),
                  [libCon.CONTAINER]: <div>
                    <SimpleCopyFieldFromATO atoId={locCon.AT_OBJECT_EMAIL_ACCOUNT} fieldId={libCon.ATF_EMAIL} title={i18n.t("goveeEmail")} processFunction={(val) => val === null || val === undefined ? null : val} />
                    <SimpleCopyFieldFromATO atoId={locCon.AT_OBJECT_EMAIL_ACCOUNT} fieldId={libCon.ATF_GOVEE_PASSWORD} title={i18n.t("goveePassword")} onCopyCleanUp={() => setStoredValue(locCon.SUG_CREATE_ACCOUNT, true)} />
                  </div>
                }} />
                <div className="horizontalLine" />

                {/* Fitbit set up */}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("fitBitApplication")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("fitBitApplicationText")}</CustomText>
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.FTB_DOWNLOAD_APPLICATION, [libCon.TEXT]: i18n.t("downloadFitbitApp") }} />
                <CheckboxGroup mainCheckbox={{
                  [libCon.ID]: locCon.FTB_CREATE_NEW_ACCOUNT, [libCon.TEXT]: i18n.t("lognWithGoogleAccount")
                }} />
                <CheckboxGroup mainCheckbox={{
                  [libCon.ID]: locCon.FTB_SET_TIME_ZONE, [libCon.TEXT]: i18n.t("setTimeZone"),
                  [libCon.CONTAINER]: <SimpleCopyField title={i18n.t("fitbitTimeZone")} value={locCon.FITBIT_TIME_ZONE} />
                }} />
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.FTB_SET_UP_DEVICE, [libCon.TEXT]: i18n.t("setUpWearableDevice") }} />

                <div className="horizontalLine" />

                {/* Location Tracker App*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("locationTrackerApp")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("locationTrackerAppText")}</CustomText>
                <CheckboxGroup
                  mainCheckbox={{ [libCon.ID]: locCon.TA_SET_UP_TRACKER_APP, [libCon.TEXT]: i18n.t("setUpLocationTrackerApp") }}
                  checkBoxArray={[
                    { [libCon.ID]: locCon.TA_DOWNLOAD_TACKER_APP, [libCon.TEXT]: i18n.t("downloadAndInstallApplication") },
                    { [libCon.ID]: locCon.TA_LOCATION_PERMISSIONS, [libCon.TEXT]: i18n.t("givePermissions") },
                    {
                      [libCon.ID]: locCon.TA_GET_PHONE_SERIAL,
                      [libCon.TEXT]: i18n.t("enterPhonesSerialNumber"),
                      [libCon.CONTAINER]: <SimpleCopyFieldFromATO atoId={locCon.AT_OBJECT_PHONE} fieldId={libCon.ATF_SERIAL} title={i18n.t("phoneSerialNumber")} />
                    },
                    { [libCon.ID]: locCon.TA_ACTIVATE_TRACKING, [libCon.TEXT]: i18n.t("activateTracking") },
                    { [libCon.ID]: locCon.TA_TURN_ON_LOCATION, [libCon.TEXT]: i18n.t("turnOnLocation") },
                    { [libCon.ID]: locCon.TA_CONFIGURE_BATTERY, [libCon.TEXT]: i18n.t("configureBatteryBehavior") },
                  ]}
                />
                <div className="horizontalLine" />


                {/* Sensor Setup*/}
                {/* Govee*/}
                {
                  bundleHasGovees
                    ? <>
                      <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("setUpGovees")}</CustomText>
                      <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("GoveeSetUpSensorText")}</CustomText>
                      <CheckboxGroup
                        mainCheckbox={{ [libCon.ID]: locCon.SUG_SET_UP_SENSOR, [libCon.TEXT]: i18n.t("setUpGovees") }}
                        checkBoxArray={[
                          {
                            [libCon.ID]: locCon.SUG_DOWNLOAD_APP,
                            [libCon.TEXT]: i18n.t("downloadGoveeApp"),
                            [libCon.CONTAINER]: <div>
                              <SimpleLink text={i18n.t("goveeApp")} link={"https://play.google.com/store/apps/details?id=com.govee.home&hl=en&gl=US"} onClickCleanup={() => setStoredValue(locCon.SUG_DOWNLOAD_APP, true)} />
                            </div>
                          },
                          {
                            [libCon.ID]: locCon.SUG_CREATE_ACCOUNT,
                            [libCon.TEXT]: i18n.t("loginGoveeAccount"),
                            [libCon.CONTAINER]: <div>
                              <SimpleCopyFieldFromATO atoId={locCon.AT_OBJECT_EMAIL_ACCOUNT} fieldId={libCon.ATF_EMAIL} title={i18n.t("goveeEmail")} processFunction={(val) => val === null || val === undefined ? null : val} />
                              <SimpleCopyFieldFromATO atoId={locCon.AT_OBJECT_EMAIL_ACCOUNT} fieldId={libCon.ATF_GOVEE_PASSWORD} title={i18n.t("goveePassword")} onCopyCleanUp={() => setStoredValue(locCon.SUG_CREATE_ACCOUNT, true)} />
                            </div>
                          }
                        ]}
                      />
                      <div className="horizontalLine" />

                    </>
                    : <div></div>
                }




                {/* Kestrel*/}
                {
                  bundleHasKestrel
                    ? <>
                      <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("setUpKestrel")}</CustomText>
                      <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("KestrelSetUpSensorText")}</CustomText>

                      <CheckboxGroup
                        mainCheckbox={{ [libCon.ID]: locCon.SUK_SET_UP_SENSOR, [libCon.TEXT]: i18n.t("setUpKestrel") }}
                        checkBoxArray={[
                          {
                            [libCon.ID]: locCon.SUK_DOWNLOAD_APP,
                            [libCon.TEXT]: i18n.t("downloadSensorApp"),
                            [libCon.CONTAINER]: <div>
                              <SimpleLink text={i18n.t("kestrelApp")} link={"https://play.google.com/store/apps/details?id=com.kestrelinstruments.kdex&hl=en&gl=US"} onClickCleanup={() => setStoredValue(locCon.SUK_DOWNLOAD_APP, true)} />
                            </div>
                          },
                          {
                            [libCon.ID]: locCon.SUK_ADD_DEVICE_TO_APP,
                            [libCon.TEXT]: i18n.t("addSensorToApp")
                          },
                          {
                            [libCon.ID]: locCon.SUK_DISABLE_AUTOCONNECT,
                            [libCon.TEXT]: i18n.t("disableAutoConnect")
                          },
                          {
                            [libCon.ID]: locCon.SUK_SET_LOG_INTERVAL,
                            [libCon.TEXT]: i18n.t("setLogInterval")
                          },

                        ]} />
                      <div className="horizontalLine" />
                    </>
                    : <div></div>

                }



                {/* Group in folder */}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("groupInFolder")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("groupInFolderText")}</CustomText>
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.GAF_GROUP_APPS_IN_FOLDER, [libCon.TEXT]: i18n.t("groupInFolderLong") }} />
                <div className="horizontalLine" />

                <CompletionStatusText checkBoxIds={allCheckboxes} />

                <div className="horizontalLine" />


              </div>
        }



      </div>
    </div>
  );
}

export default BundleSync


